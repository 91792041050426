import React, { useState } from 'react';
import './chat.css'
import Chatbot from "./chatbot";

const Chat = () => {
  const [messages, setMessages] = useState(false);
  const [userInput, setUserInput] = useState('');
  const handlechatBotClick=()=>{
    if(messages===true){
      setMessages(false)
      setUserInput(<></>)
    }
    else{
      setMessages(true)
      setUserInput(<Chatbot/>)
    }
  }


  return (
  <div className='chat-container'>
    {userInput}
    <div className='botlogo' onClick={handlechatBotClick}></div>
      {/* <img src='../images/chatbot.jpg' alt='chatbot' width={50}/> */}
  </div>
  )
};

export default Chat;