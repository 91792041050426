import React from "react";
import "./Home.css";
import Bannerhome from "./BannerHome";
import Products from "./PopularProducts";
import Trusted from "./Trusted";
import { Link } from "react-router-dom";
import Serviceinfo from "./Homeservices";
import Businessinfo from "./Homeservices2";
import Dashboard from "./Dashboard";
import FAQ from "./FAQ";
import Chat from "./chat";
import HomeFooter from "./HomeFooter";
import Slider from "./Slider";
import HomeCard from "./NewProducts/HomeCard";

function Home() {
  const authToken = localStorage.getItem("accessToken");
  const isLoggedIn = authToken !== null && authToken !== "";

  return (
    <div className="Home">
      {isLoggedIn ? (
        <>
          <Dashboard />
        </>
      ) : (
        <>
          <Bannerhome />
          <div className="testimonial">
            <h3> ITR filing with Marchclosing</h3>
          </div>
          <div className="testimonials">
            {" "}
            <HomeCard />
          </div>
          <div className="expert">
            <div className="innercontainer">
              <h2>
                {" "}
                Looking for <span style={{ color: "#dd3333" }}>
                  Expert
                </span>{" "}
                services?{" "}
              </h2>
              <p>
                In addition to providing software services, we also offer expert
                services to help you manage your business and legal compliances.
              </p>
              <div className="inside">
                <div className="little">
                  <div className="leftflex">
                    <h3> For You </h3>
                    <Serviceinfo />
                    <h5>
                      <Link
                        to={"/contactus"}
                        style={{ textDecoration: "none" }}
                      >
                        <span style={{ color: "#dd3333" }}>
                          See all services &#x279C;
                        </span>
                      </Link>
                    </h5>
                  </div>
                </div>
                <div className=" big">
                  <div className="leftflex">
                    <h3> For your business</h3>

                    <Businessinfo />
                    <h5>
                      <Link
                        to={"/contactus"}
                        style={{ textDecoration: "none" }}
                      >
                        <span style={{ color: "#dd3333" }}>
                          See all services &#x279C;
                        </span>
                      </Link>
                    </h5>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>

          <div className="outernewCard">
            <Products />
          </div>

          <Slider />

          <Trusted />
          <Chat />
          <div className="FAQOuter">
            <FAQ />
            <hr />
            <HomeFooter />
          </div>
        </>
      )}
    </div>
  );
}

export default Home;
