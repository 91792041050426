import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Card } from "react-bootstrap";
import Banner from "./Banner";
import "./guide.css";

const data_articles = [
  {
    id: "a1",
    link: "/article1",
    title: "New Tax Regime",
    description: "All you need to know about new tax regime.",
    // img: require("../images/article1.png"),
  },
  {
    id: "a2",
    link: "/article2",
    title: "Why I am paying tax",
    description: "The real story behind tax game.",
    // img: require("../images/article2.png"),
  },
  {
    id: "a3",
    link: "/article3",
    title: "E-Invoicing",
    description: "How E-Invoicing can be game changer.",
    // img: require("../images/article3.png"),
  },
  {
    id: "a4",
    link: "/article4",
    title: "GST in 2023",
    description: "Get all info .",
    // img: require("../images/article4.png"),
  },
  {
    id: "a5",
    link: "/article5",
    title: "Budget 2023-2024",
    description: "Understand positives and negatives.",
    // img: require("../images/article4.png"),
  },
  {
    id: "a6",
    link: "/article6",
    title: "Use of tech in accounting",
    description: "GET insights about best tech.",
    // img: require("../images/article4.png"),
  },
];

const Guide = () => {
  const articles = data_articles.map((article) => (
    <Col key={article.id} md={6} lg={3}>
      <Link to={article.link} style={{ textDecoration: "none" }}>
        <Card className="guide-card">
          <Card.Body>
            <Card.Title>{article.title}</Card.Title>
            <Card.Text>{article.description}</Card.Text>
          </Card.Body>
        </Card>
      </Link>
    </Col>
  ));

  return (
    <div>
      <Banner
        h1Content="Explore Our"
        h1Span={<span style={{ color: "#dd3333" }}> Guide</span>}
        pageDescription="Discover helpful articles for your financial well-being."
      />

      <Container>
        <Row className="justify-content-center">{articles}</Row>
      </Container>
    </div>
  );
};

export default Guide;
