import React from "react";
import { useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ReCAPTCHA from "react-google-recaptcha";

const ResetPassword = () => {
  let reRef = useRef(null);
  const navigate = useNavigate();

  const params = useParams();
  const Token = params.token;
  const [password, setPassword] = useState("");
  let [emailerr, setEmailerr] = useState("");
  // let [newpage, setNewpage] = useState("false");

  const test = async () => {
    const ctoken = await reRef.current.executeAsync();
    const data = { passwd: password, ctoken };
    var bearer = "Bearer " + Token;
    const res = await fetch("https://marchclosing/api/oauth/v1/set/password", {
      method: "POST",
      // mode: "no-cors",
      body: JSON.stringify(data),
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const data2 = await res.json();
    if (data2.error) {
      setEmailerr(data2.error);
    }
    if (data2.status === "Successfull") {
      setEmailerr("Password change successfully");
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  };

  let button;
  if (password === "") {
    button = (
      <div className="btnnn">
        <button className="submitme2">SUBMIT</button>
      </div>
    );
  } else {
    button = (
      <div className="btnnn">
        <button className="submitme" type="submit" onClick={test}>
          SUBMIT
        </button>
      </div>
    );
  }
  return (
    <div className="main">
      <div className="contain">
        <ReCAPTCHA
          sitekey="6LcizEclAAAAACbd6POvWUG2H1nRuUxPXtQOfvBp"
          size="invisible"
          ref={reRef}
        ></ReCAPTCHA>
        <div className="page2">
          <div className="signin box">
            <h1>Reset Your Password</h1>
          </div>
          <div className="instructionPass">
            <p>
              Having trouble loggin in ?{" "}
              <ul>
                <li>Username are in the form of an email address.</li>
                <li>Password are case sensitive.</li>
                <li>
                  To reset your password ,enter your username registered with
                  March Closing
                </li>
              </ul>
            </p>
          </div>
          <div className="email box">
            <Form className="formcss">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <label htmlFor="emails">NEW PASSWORD</label>
                <br></br>
                <input className="FormInput"
                  type="password"
                  required
                  value={password}
                  placeholder="Enter Your New Password"
                  id="emails"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setEmailerr("");
                  }}
                />
              </Form.Group>
            </Form>
            <div className="emailer error">{emailerr}</div>
          </div>
          {button}
          <div className="footerlogin">
            <a href={"/auth?mode=login"} style={{ textDecoration: "none" }}>
              <span>Back To Log In</span>
            </a>
          </div>
          <div className="footerlogin">
            Don't have an account?{" "}
            <a href={"/auth?mode=signup"} style={{ textDecoration: "none" }}>
              <span>Sign Up</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
