import React from "react";
import { useLocation } from "react-router-dom";
import './AfterVerification.css'

const useQuery=()=>{
    return new URLSearchParams(useLocation().search)
}

function EmailVerify() {
    const query=useQuery();
    const verified=query.get('success')||"";
        if(verified==="error" || verified===""){
            return (
                <div className="MainVerify">
                <div className="containerVerify">
                    <div className="headingVerify boxVerify"><h1>Expired/Invalid verification Link</h1></div>
                    <div className="bodyVerify boxVerify"><p>Verification link Expired after 15 minutes. Please try recreating the account to receive the link</p></div>
                </div>
                </div>
            );
        }
            else if(verified==="true"){
                return (
                    <div className="MainVerify">
                    <div className="containerVerify">
                        <div className="headingVerify boxVerify"><h1>Email Verification Complete</h1></div>
                        <div className="bodyVerify boxVerify"><p>Thanks for signing up, Now you can Login into your account & access to our services.</p></div>
                    </div>
                    </div>
                );
            }
            else{
                return (
                    <div className="MainVerify">
                    <div className="containerVerify">
                        <div className="headingVerify boxVerify"><h1>Expired/Invalid verification Link</h1></div>
                        <div className="bodyVerify boxVerify"><p>Verification link Expired after 15 minutes. Please try recreating the account to receive the link</p></div>
                    </div>
                    </div>
                );
            }

}

export default EmailVerify;
