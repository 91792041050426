import React from "react";
const data_FAQ=[
    {
        id:'q1',
        question:'Who should file an ITR?',
        answer:'An individual whose annual income is more than the basic exemption limit of Rs 2.5 lakh should file an ITR. The basic exemption limit for senior citizens (60 years onwards and less than 80 years) is Rs 3 lakh, and for super senior citizens is Rs 5 lakh.'
    },
    {
        id:'q2',
        question:'How can I claim deductions for tax saving?',
        answer:'You should file an income tax return to claim tax deductions for tax savings such as ELSS, PPF, NSC investments and for payments such as housing loan repayments, insurance premium and donations.'
    },
    {
        id:'q3',
        question:'I receive my salary income after deduction of TDS. Am I required to file an income tax return?',
        answer:'You are required to file an income tax return once your annual income exceeds Rs 2.5 lakh. A deduction of TDS does not replace the requirement to file ITR. While e-filing your ITR, you should furnish the details of your annual income, claim deductions and credit for TDS deducted by your employer.'
    },
    {
        id:'q4',
        question:'How do I check TDS details from my form 26AS?',
        answer:'You can check your Form 26AS from your e-filing login. March Closing’s e-filing software auto-populates the TDS details from your Form 26AS in your income tax return.'
    },
    {
        id:'q5',
        question:'What is income tax?',
        answer:'Income tax is a direct tax imposed by the government on individuals, entities, or organizations based on their income or profits. It is a major source of revenue for the government to fund public services and infrastructure.'
    },
    {
        id:'q6',
        question:'What are tax deductions and exemptions?',
        answer:'Tax deductions and exemptions are provisions that reduce the taxable income, thereby reducing the overall tax liability. These deductions can include expenses such as medical bills, education expenses, charitable donations, home loan interest, and contributions to retirement accounts.'
    },
    {
        id:'q7',
        question:'How can I file my income tax return?',
        answer:"Income tax returns can be filed online through the government's designated portal or through authorized intermediaries. You will need to gather relevant documents such as Form 16 (provided by your employer), investment proofs, and bank statements. The process involves reporting your income, deductions, and tax liabilities accurately."
    },
];
function FAQ() {
    const ques=data_FAQ.map(card=><div className="questionsOuter">
        <div className="question"><h1>{card.question}</h1></div>
        <div className="answer"><h2>{card.answer}</h2></div>
    </div>)
  return (
    <div className="FAQ">
        <div className="FAQHeading">Frequently Asked Questions</div>
        <div className="FAQBody">{ques}</div>
    </div>
  );
}

export default FAQ;