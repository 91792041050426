import React from "react";
import "./options.css";


const Options = (props) => {
    console.log('.........',props)
  const options = [
    {
      text: "Yes",
      handler: props.actionProvider.handleJavascriptQuiz,
      id: 1,
    },
    { text: "No", handler: () => {}, id: 2 },
  ];
  const buttonsMarkup = options.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

const YesOptions = (props) => {
  const yesOptions = [
    {text: " Income Tax calculator", handler: ()=>{window.open('/tax','_blank')}, id: 1,},
    { text: "HRA calculator", handler: () => {window.open('/hracalculator','_blank')}, id: 2 },
    { text: "GST calculator", handler: () => {window.open('/gstcalculator','_blank')}, id: 3 },
    { text: "Rent Slip", handler: () => {window.open('/rentslip','_blank')}, id: 4 },
    { text: "Contact with Advisor", handler: () => {window.open('/contactus','_blank')}, id: 5 },
  ];
  const buttonsMarkup = yesOptions.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

const NoOptions = (props) => {
  const noOptions = [
    {text: "Income Tax related", handler: ()=>{}, id: 1,},
    { text: "HRA/Rent Slip related", handler: () => {}, id: 2 },
    { text: "GST related", handler: () => {}, id: 3 },
    { text: "Bussiness related solutions", handler: () => {}, id: 4 },
    { text: "Contact with Advisor", handler: () => {}, id: 5 },
    { text: "Join March Closing", handler: () => {}, id: 6 },
  ];
  const buttonsMarkup = noOptions.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};
const IncomeOptions = (props) => {
  const noOptions = [
    {text: "Income Tax calculator", handler: ()=>{window.open('/tax','_blank')}, id: 1,},
    { text: "Guidance on tax benefits and savings", handler: () => {}, id: 2 },
    { text: "Tax refund status", handler: () => {}, id: 3 },
    { text: "File Income tax", handler: () => {}, id: 4 },
    { text: "Contact with Advisor", handler: () => {}, id: 5 },
  ];
  const buttonsMarkup = noOptions.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};
const HRAOptions = (props) => {
  const noOptions = [
    {text: "HRA calculator", handler: ()=>{window.open('/hracalculator','_blank')}, id: 1,},
    { text: "Guidance on tax benefits and savings", handler: () => {}, id: 2 },
    { text: "Generate Rent Slips", handler: () => {window.open('/rentslip','_blank')}, id: 3 },
    { text: "Contact with Advisor", handler: () => {}, id: 5 },
  ];
  const buttonsMarkup = noOptions.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};
const GSTOptions = (props) => {
  const noOptions = [
    {text: "GST calculator", handler: ()=>{window.open('/gstcalculator','_blank')}, id: 1,},
    { text: "Apply for GST", handler: () => {}, id: 2 },
    { text: "Fill GST return", handler: () => {window.open('/rentslip','_blank')}, id: 3 },
    { text: "Contact with Advisor", handler: () => {}, id: 4 },
  ];
  const buttonsMarkup = noOptions.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export {Options,YesOptions, NoOptions,IncomeOptions,HRAOptions,GSTOptions};