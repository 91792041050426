// MessageParser starter code
class MessageParser {
    constructor(actionProvider, state) {
      this.actionProvider = actionProvider;
      this.state = state;
    }
  
    parse(message) {
      message=message.toLowerCase();
      if(message.includes("hello") || message.includes("hi") || message.includes("hey") || message.includes("helo")){
        this.actionProvider.greet();
      }
      if(message.includes('yes')){
        this.actionProvider.yesOption();
      }
      if(message.includes('no')){
        this.actionProvider.noOption();
      }
      if(message.includes('income')){
        this.actionProvider.IncomeOption();
      }
      if(message.includes('hra') || message.includes('rent')){
        this.actionProvider.HRAOption();
      }
      if(message.includes('gst') || message.includes('tax')){
        this.actionProvider.GSTOption();
      }
    }
  }
  
  export default MessageParser;