import React from "react";
import "./Contact.css";
import Banner from "./Banner";
import Form from "./Form";
function Contact() {
  return (
    <div>
      <div className="contactus">
        <Banner
          h1Content=" How can we "
          h1Span={<span style={{ color: "#dd3333" }}>help</span>}
          h1Content2="?"
          pageDescription="Let us know how can we share our hands and own your tax & saving
          needs."
        />

        <div className="insidecontainer">
          <div className="raiserequest">
            <h4>Hope you are doing great ! We are just a click away .</h4>
            <div className="details">
              <h6>
                Provide details and submit the form , we will reach you out soon{" "}
              </h6>
              <h6>
                Or you can directly call us on{" "}
                <span>
                  {" "}
                  <a href="tel:+918528312010">+91-8528312010</a>
                </span>
              </h6>
            </div>
          </div>
          <Form />
        </div>
      </div>
    </div>
  );
}

export default Contact;
