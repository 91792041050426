import { useState } from "react";
//import Dropdown from "react-bootstrap/Dropdown";
import "./Existing.css";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import marchclosing from "../images/marchclosing.png";

function Existing() {
  const min1 = 0;
  const max1 = 150000;
  const min2 = 0;
  const max2 = 50000;
  const min3 = 0;
  const max3 = 10000;

  const min6 = 0;
  const max6 = 150000;
  const min7 = 0;
  const max7 = 50000;
  var deductions;
  // const [showParagraph, setShowParagraph] = useState(false);
  const [value, setValue] = useState(0);
  var [deduction2, setDeduction2] = useState(0);
  var [deduction3, setDeduction3] = useState(0);
  var [deduction4, setDeduction4] = useState(0);
  var [deduction5, setDeduction5] = useState(0);
  var [deduction6, setDeduction6] = useState(0);
  var [deduction7, setDeduction7] = useState(0);

  // added this to implement max value functionality

  const handleChange = (event) => {
    const value = Math.max(min1, Math.min(max1, Number(event.target.value)));
    setValue(value);
  };

  const handleChange2 = (event) => {
    const deduction2 = Math.max(
      min2,
      Math.min(max2, Number(event.target.value))
    );
    setDeduction2(deduction2);
  };
  const handleChange3 = (event) => {
    const deduction4 = Math.max(
      min3,
      Math.min(max3, Number(event.target.value))
    );
    setDeduction4(deduction4);
  };
  const handleChange6 = (event) => {
    const deduction6 = Math.max(
      min6,
      Math.min(max6, Number(event.target.value))
    );
    setDeduction6(deduction6);
  };
  const handleChange7 = (event) => {
    const deduction7 = Math.max(
      min7,
      Math.min(max7, Number(event.target.value))
    );
    setDeduction7(deduction7);
  };

  const handleSubmit = () => {
    if (!income || !age) {
      alert("Please enter both income and age");
      return;
    }
  };

  const [income, setIncome] = useState(0);
  var [tax, setTax] = useState(0);
  var [nincome, setNincome] = useState();
  var [taxincome, setTaxincome] = useState(0);

  var [taxincome1, setTaxincome1] = useState(0);
  var [age, setAge] = useState("< 60");
  var [year, setYear] = useState();
  let deduction9 = 50000; // standard deduction for old regime
  let deduction8 = 50000; // for new regime ( it would be zero for 22-23 and 50000 for 23-24)
  if (year === 2022) {
    deduction8 = 0; // set to zero if 2022 is selected
  }
  if (income < 150000) {
    deduction8 = 0;
    deduction9 = 0;
  }
  //var [deduction1, setDeduction1] = useState(0); // setting up the deductions here

  deductions =
    value +
    deduction2 +
    deduction3 +
    deduction4 +
    deduction5 +
    deduction6 +
    deduction7;

  taxincome = income - deductions - deduction9;
  const finaltax1 = Math.round(taxincome);
  taxincome1 = income - deduction8;
  const finaltax2 = Math.round(taxincome1);

  if (income < 50000) {
    taxincome = 0;
    taxincome1 = 0;
  }
  if (income < 150000) {
    deductions = 0;
  }
  function exCalculation() {
    if (age === "< 60") {
      if (taxincome > 1000000) {
        tax =
          ((((taxincome - 1000000) * 30) / 100 + 112500) * 4) / 100 +
          (((taxincome - 1000000) * 30) / 100 + 112500); //   tax here is 30 % plus 12500 inr
      } else if (taxincome > 500000) {
        tax =
          ((((taxincome - 500000) * 20) / 100 + 12500) * 4) / 100 +
          (((taxincome - 500000) * 20) / 100 + 12500); // tax here is 20 % plus 12500 inr
      } else {
        tax = 0;
      }
    } else if (age === "< 80") {
      if (taxincome > 1000000) {
        tax =
          ((((taxincome - 1000000) * 30) / 100 + 110000) * 4) / 100 +
          (((taxincome - 1000000) * 30) / 100 + 110000); //   tax here is 30 % plus 110000 inr
      } else if (taxincome > 500000) {
        tax =
          ((((taxincome - 500000) * 20) / 100 + 10000) * 4) / 100 +
          (((taxincome - 500000) * 20) / 100 + 10000); // tax here is 20 % plus 10000 inr
      } else {
        tax = 0;
      }
    } else if (age === "< 150") {
      if (taxincome > 1000000) {
        tax =
          ((((taxincome - 1000000) * 30) / 100 + 100000) * 4) / 100 +
          (((taxincome - 1000000) * 30) / 100 + 100000); //   tax here is 30 % plus 110000 inr
      } else if (taxincome > 500000) {
        tax =
          ((((taxincome - 500000) * 20) / 100) * 4) / 100 +
          ((taxincome - 500000) * 20) / 100; // tax here is 20 % plus 10000 inr
      } else {
        tax = 0;
      }
    }

    setTax(tax);
    setTaxincome1(taxincome1);
    setTaxincome(taxincome);
    nincome = taxincome - tax;

    setNincome(nincome);
  }
  const [showModal, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  var [tax1, setTax1] = useState(0);
  const [activeInput, setActiveInput] = useState(null);
  const handleInputClick = (e) => {
    setActiveInput(e.target.name);
  };

  //popup enhancement
  const isOldRegimeBetter = tax < tax1;
  const taxDifference = Math.abs(tax - tax1);

  const handlePrint = () => {
    window.print();
  };
  //till here
  function Calculation() {
    if (year === 2022) {
      if (age === "< 60") {
        if (income >= 1500000) {
          //   tax here is 30 % above 1500000 plus 187000 inr
          tax1 = 1.04 * (((taxincome1 - 1500000) * 30) / 100 + 187500);
        } else if (income > 1250000) {
          tax1 = 1.04 * (((taxincome1 - 1250000) * 25) / 100 + 125000); // tax here is 25 % plus 125000 inr ;;; multiplying with 1.04 to include 4%  cess on tax
        } else if (income > 1000000) {
          tax1 = 1.04 * (((taxincome1 - 1000000) * 20) / 100 + 75000); // tax here is 15 % plus 37500 inr
        } else if (income > 750000) {
          tax1 = 1.04 * (((taxincome1 - 750000) * 15) / 100 + 37500); // tax here is 10 % plus 12500 inr
        } else if (income > 500000) {
          tax1 = 1.04 * (((taxincome1 - 500000) * 10) / 100 + 12500); // tax here is 5 %
        } // else if (income > 250000) {
        //   tax1 = 1.04 * (((taxincome1 - 250000) * 5) / 100); // tax here is 5 %
        // }
        else {
          tax1 = 0;
        }
      } else if (age === "< 80") {
        if (income >= 1500000) {
          //   tax here is 30 % above 1500000 plus 187000 inr
          tax1 = 1.04 * (((taxincome1 - 1500000) * 30) / 100 + 187500);
        } else if (income > 1250000) {
          tax1 = 1.04 * (((taxincome1 - 1250000) * 25) / 100 + 125000); // tax here is 25 % plus 125000 inr ;;; multiplying with 1.04 to include 4%  cess on tax
        } else if (income > 1000000) {
          tax1 = 1.04 * (((taxincome1 - 1000000) * 20) / 100 + 75000); // tax here is 15 % plus 37500 inr
        } else if (income > 750000) {
          tax1 = 1.04 * (((taxincome1 - 750000) * 15) / 100 + 37500); // tax here is 10 % plus 12500 inr
        } else if (income > 500000) {
          tax1 = 1.04 * (((taxincome1 - 500000) * 10) / 100 + 12500); // tax here is 5 %
        } // else if (income > 250000) {
        //   tax1 = 1.04 * (((taxincome1 - 250000) * 5) / 100); // tax here is 5 %
        // }
        else {
          tax1 = 0;
        }
      } else if (age === "< 150") {
        if (income >= 1500000) {
          //   tax here is 30 % above 1500000 plus 187000 inr
          tax1 = 1.04 * (((taxincome1 - 1500000) * 30) / 100 + 187500);
        } else if (income > 1250000) {
          tax1 = 1.04 * (((taxincome1 - 1250000) * 25) / 100 + 125000); // tax here is 25 % plus 125000 inr ;;; multiplying with 1.04 to include 4%  cess on tax
        } else if (income > 1000000) {
          tax1 = 1.04 * (((taxincome1 - 1000000) * 20) / 100 + 75000); // tax here is 15 % plus 37500 inr
        } else if (income > 750000) {
          tax1 = 1.04 * (((taxincome1 - 750000) * 15) / 100 + 37500); // tax here is 10 % plus 12500 inr
        } else if (income > 500000) {
          tax1 = 1.04 * (((taxincome1 - 500000) * 10) / 100 + 12500); // tax here is 5 %
        } // else if (income > 250000) {
        //   tax1 = 1.04 * (((taxincome1 - 250000) * 5) / 100); // tax here is 5 %
        // }
        else {
          tax1 = 0;
        }
      }
    } else if (year === 2023) {
      if (age === "< 60") {
        if (income >= 1500000) {
          //   tax here is 30 % above 1500000 plus 187000 inr
          tax1 = 1.04 * (((taxincome1 - 1500000) * 30) / 100 + 150000);
        } else if (income > 1200000) {
          tax1 = 1.04 * (((taxincome1 - 1200000) * 20) / 100 + 90000); // tax here is 25 % plus 125000 inr ;;; multiplying with 1.04 to include 4%  cess on tax
        } else if (income > 900000) {
          tax1 = 1.04 * (((taxincome1 - 900000) * 15) / 100 + 45000); // tax here is 15 % plus 37500 inr
        } else if (income > 750000) {
          tax1 = 1.04 * (((taxincome1 - 600000) * 10) / 100 + 15000); // tax here is 10 % plus 12500 inr
          // } else if (income > 300000) {
          //   tax1 = 1.04 * (((taxincome1 - 300000) * 5) / 100); // tax here is 5 %
        } else {
          tax1 = 0;
        }
      } else if (age === "< 80") {
        if (income >= 1500000) {
          //   tax here is 30 % above 1500000 plus 187000 inr
          tax1 = 1.04 * (((taxincome1 - 1500000) * 30) / 100 + 150000);
        } else if (income > 1200000) {
          tax1 = 1.04 * (((taxincome1 - 1200000) * 20) / 100 + 90000); // tax here is 25 % plus 125000 inr ;;; multiplying with 1.04 to include 4%  cess on tax
        } else if (income > 900000) {
          tax1 = 1.04 * (((taxincome1 - 900000) * 15) / 100 + 45000); // tax here is 15 % plus 37500 inr
        } else if (income > 750000) {
          tax1 = 1.04 * (((taxincome1 - 600000) * 10) / 100 + 15000); // tax here is 10 % plus 12500 inr
          // } else if (income > 300000) {
          //   tax1 = 1.04 * (((taxincome1 - 300000) * 5) / 100); // tax here is 5 %
        } else {
          tax1 = 0;
        }
      } else if (age === "< 150") {
        if (income >= 1500000) {
          //   tax here is 30 % above 1500000 plus 187000 inr
          tax1 = 1.04 * (((taxincome1 - 1500000) * 30) / 100 + 150000);
        } else if (income > 1200000) {
          tax1 = 1.04 * (((taxincome1 - 1200000) * 20) / 100 + 90000); // tax here is 25 % plus 125000 inr ;;; multiplying with 1.04 to include 4%  cess on tax
        } else if (income > 900000) {
          tax1 = 1.04 * (((taxincome1 - 900000) * 15) / 100 + 45000); // tax here is 15 % plus 37500 inr
        } else if (income > 750000) {
          tax1 = 1.04 * (((taxincome1 - 600000) * 10) / 100 + 15000); // tax here is 10 % plus 12500 inr
          // } else if (income > 300000) {
          //   tax1 = 1.04 * (((taxincome1 - 300000) * 5) / 100); // tax here is 5 %
        } else {
          tax1 = 0;
        }
      }
    }
    setTax1(tax1);

    nincome = income - tax;

    setNincome(nincome);
  }

  // popup functionality function

  return (
    <div className="mainbox">
      <div className="containerold">
        <div className="form-group">
          <label className="label">
            Financial Year <span className="warning"> *</span>
          </label>
          <select
            name="input14"
            onClick={handleInputClick}
            className="inputboxmain"
            onChange={(event) => {
              setYear(parseInt(event.target.value));
            }}
          >
            <option value="">Select a Year</option>
            <option value="2023">2023-2024</option>
            <option value="2022">2022-2023</option>
          </select>
        </div>
        <div class="form-group">
          <label className="label"> Name </label>
          <input
            name="input1"
            onClick={handleInputClick}
            type="text"
            className="inputboxmain"
            placeholder="Name"
          />
        </div>
        {activeInput === "input1" && (
          <div className="helpinfo">
            {" "}
            Enter your name here. कृपया अपना नाम दर्ज करें
          </div>
        )}
        <div class="form-group">
          <label className="label" style={{ color: "#dd3333" }}>
            {" "}
            Income ( Annual income in ₹) <span className="warning"> *</span>
          </label>
          <input
            type="text"
            name="input2"
            onClick={handleInputClick}
            className="inputboxmain"
            placeholder="Enter Income"
            onChange={(event) => {
              setIncome(parseInt(event.target.value));
            }}
          />
        </div>
        {activeInput === "input2" && (
          <div className="helpinfo"> Enter salary. वेतन दर्ज करें</div>
        )}
        <div className="form-group">
          <label className="label">
            Age <span className="warning"> *</span>
          </label>
          <select
            name="input3"
            onClick={handleInputClick}
            className="inputboxmain"
            onChange={(event) => {
              setAge(event.target.value);
            }}
          >
            <option value="">Select Age group</option>
            <option value="< 60">Below 60</option>
            <option value="< 80">Between 60 & 80</option>
            <option value="< 150">Above 80</option>
          </select>
        </div>
        {/* <div class="form-group">
          <label className="label">
            {" "}
            Age ( Your age in Years ) <span className="warning"> *</span>
          </label>
          <input
            type="text"
            name="input3"
            onClick={handleInputClick}
            className="inputboxmain"
            placeholder="Enter Age"
            onChange={(event) => {
              setAge(event.target.value);
            }}
          />
        </div>
        {activeInput === "input3" && (
          <div className="helpinfo">Enter age . आयु दर्ज करें </div>
        )} */}
        <div class="form-group">
          <label className="label"> Basic Deductions - 80C </label>
          <input
            type="text"
            name="input4"
            onClick={handleInputClick}
            className="inputboxmain"
            placeholder=""
            onChange={handleChange}
          />
        </div>{" "}
        {activeInput === "input4" && (
          <div className="helpinfo">
            <p>
              SECTION 80C: Section 80C allows a maximum deduction of{" "}
              <span>Rs 1.5 lakh</span> every year from the taxpayer's total
              income. The benefit of this deduction can be availed by
              Individuals and HUFs. It is important to note that the overall
              limit, including the subsections for claiming a deduction, is Rs
              1.5 lakh, except for an additional deduction of Rs 50,000 allowed
              under section 80CCD(1b).
            </p>
            <p>
              धारा 80 सी: करदाताओं की कुल आय से हर साल अधिकतम{" "}
              <span>1.5 लाख</span> रुपये की कटौती की अनुमति देती है। इस कटौती का
              लाभ व्यक्तियों और एचयूएफ द्वारा उठाया जा सकता है। यह ध्यान रखना
              महत्वपूर्ण है कि कटौती का दावा करने के लिए उपधाराओं सहित कुल सीमा
              1.5 लाख रुपये है, धारा 80सीसीडी(1बी) के तहत 50,000 रुपये की
              अतिरिक्त कटौती की अनुमति को छोड़कर।
            </p>
          </div>
        )}
        <div class="form-group">
          <label className="label"> Medical Insurance - 80D </label>
          <input
            type="text"
            name="input5"
            onClick={handleInputClick}
            className="inputboxmain"
            placeholder=""
            onChange={handleChange2}
          />
        </div>{" "}
        {activeInput === "input5" && (
          <div className="helpinfo">
            <p>
              The deduction allowed under Section 80D is Rs 25,000 in a
              financial year. In the case of senior citizens, the deduction
              limit allowed is <span> Rs 50,000 </span>. Individual: An
              individual can claim a deduction of up to Rs 25,000 for the
              insurance of self, spouse, and dependent children.
            </p>
            <p>
              धारा 80डी के तहत एक वित्तीय वर्ष में कटौती की अनुमति{" "}
              <span> 25,000 </span>
              रुपये है। वरिष्ठ नागरिकों के मामले में, कटौती की सीमा 50,000 रुपये
              है। व्यक्तिगत: एक व्यक्ति स्वयं, जीवनसाथी और आश्रित बच्चों के बीमा
              के लिए 25,000 रुपये तक की कटौती का दावा कर सकता है।
            </p>
          </div>
        )}
        <div class="form-group">
          <label className="label"> Donations to Charity - 80G </label>
          <input
            type="text"
            name="input6"
            onClick={handleInputClick}
            className="inputboxmain"
            placeholder=""
            onChange={(event) => {
              setDeduction3(parseInt(event.target.value));
            }}
          />
        </div>
        {activeInput === "input6" && (
          <div className="helpinfo">
            <p>
              Donations under 80G should be made to local authority or
              government which is used for any charitable purposes. Only{" "}
              <span>10%</span>
              of the Adjusted Gross Income becomes eligible for deductions.
              However, an amount exceeding this limit will be capped at 10%.
            </p>
            <p>
              80जी के तहत दान स्थानीय प्राधिकरण या सरकार को दिया जाना चाहिए
              जिसका उपयोग किसी धर्मार्थ उद्देश्य के लिए किया जाता है। समायोजित
              सकल आय का केवल<span>10%</span> ही कटौती के लिए पात्र बनता है।
              हालाँकि, इस सीमा से अधिक की राशि पर 10% की सीमा तय की जाएगी।
            </p>
          </div>
        )}
        <div class="form-group">
          <label className="label"> Interest from Deposits - 80TTA </label>
          <input
            type="text"
            name="input7"
            onClick={handleInputClick}
            className="inputboxmain"
            placeholder=""
            onChange={handleChange3}
          />
        </div>{" "}
        {activeInput === "input7" && (
          <div className="helpinfo">
            <p>
              Section 80TTA of the Income Tax Act, 1961 allows a deduction of up
              to <span>INR 10,000</span> on interest income from savings in
              banks, cooperatives, or post offices. No deduction is provided for
              fixed deposit interest.
            </p>
            <p>
              आयकर अधिनियम, 1961 की धारा 80TTA बैंकों, सहकारी समितियों या
              डाकघरों में बचत से ब्याज आय पर <span> 10,000 रुपये</span> तक की
              कटौती की अनुमति देती है। सावधि जमा ब्याज के लिए कोई कटौती प्रदान
              नहीं की जाती है।
            </p>
          </div>
        )}
        <div class="form-group">
          <label className="label"> Interest on Educational Loan - 80E</label>
          <input
            type="text"
            name="input8"
            onClick={handleInputClick}
            className="inputboxmain"
            placeholder=""
            onChange={(event) => {
              setDeduction5(parseInt(event.target.value));
            }}
          />
        </div>{" "}
        {activeInput === "input8" && (
          <div className="helpinfo">
            <p>
              Only
              <span>
                {" "}
                interest paid on loans taken for higher education is eligible
              </span>{" "}
              for deduction. Repayment of the principal portion of the loan
              taken for higher education does not qualify for a deduction.
            </p>
            <p>
              केवल{" "}
              <span>उच्च शिक्षा के लिए लिए गए ऋण पर भुगतान किया गया ब्याज</span>{" "}
              ही कटौती के लिए पात्र है। उच्च शिक्षा के लिए लिए गए ऋण के मूलधन का
              पुनर्भुगतान कटौती के लिए योग्य नहीं है।
            </p>
          </div>
        )}
        <div class="form-group">
          <label className="label"> Interest on Housing Loan - 80EEA </label>
          <input
            type="text"
            name="input9"
            onClick={handleInputClick}
            className="inputboxmain"
            placeholder=""
            onChange={handleChange6}
          />
        </div>{" "}
        {activeInput === "input9" && (
          <div className="helpinfo">
            <p>
              Property stamp value must be <span>below Rs 45 lakhs</span>. Home
              loans sanctioned from April 1, 2019 to March 31, 2022, qualify for
              benefits. Only individuals can claim deductions. Loan must be
              obtained from a financial institution. Deduction limit is Rs
              <span>1.50 lakhs</span> per year.
            </p>
            <p>
              संपत्ति का स्टांप मूल्य<span> 5 लाख </span> 4रुपये से कम होना
              चाहिए। 1 अप्रैल, 2019 से 31 मार्च, 2022 तक स्वीकृत गृह ऋण लाभ के
              लिए पात्र हैं। केवल व्यक्ति ही कटौती का दावा कर सकते हैं। किसी
              वित्तीय संस्थान से ऋण प्राप्त करना होगा। कटौती की सीमा{" "}
              <span>1.50 </span>
              लाख रुपये प्रति वर्ष है.
            </p>
          </div>
        )}
        <div class="form-group">
          <label className="label">
            {" "}
            Employee's contribution to NPS (Addition) - 80CCD(1B){" "}
          </label>
          <input
            type="text"
            name="input10"
            onClick={handleInputClick}
            className="inputboxmain"
            placeholder=""
            onChange={handleChange7}
          />
        </div>{" "}
        {activeInput === "input10" && (
          <div className="helpinfo">
            <p>
              NPS subscribers can get an extra deduction of up to{" "}
              <span>Rs. 50,000</span>
              under section 80CCD (1B) for investing in NPS Tier I account. This
              is in addition to the existing Rs. 1.5 lakh deduction under
              section 80C of the Income Tax Act, 1961.
            </p>
            <p>
              एनपीएस ग्राहकों को एनपीएस टियर I खाते में निवेश के लिए धारा 80CCD
              (1B) के तहत <span> 50,000 </span>रुपये तक की अतिरिक्त कटौती मिल
              सकती है। यह मौजूदा आयकर अधिनियम, 1961 की धारा 80 सी के तहत 1.5 लाख
              की कटौती के अतिरिक्त है।
            </p>
          </div>
        )}
        {/* <div class="form-group">
          <label className="label"> Taxable Income</label>
          <input
            type="text"
            className="inputboxmain"
            placeholder="Taxable Income"
            value={taxincome}
          />
        </div> */}
        <button
          disabled={!income || !age}
          className="submit"
          type="submit"
          onClick={() => {
            if (!income || !age) {
              console.log("working");
              alert(
                "Please fill in both income and age fields before calculating."
              );
            } else {
              Calculation();
              exCalculation();
              handleShow();
              handleSubmit();
            }
          }}
        >
          Calculate
        </button>
      </div>

      <div className="outerboxinfotax">
        {/* {activeInput && (
          <div className="infoboxmain14">
            {activeInput === "input14" && " Select financial year"}
          </div>
        )} */}

        {activeInput && (
          <div className="infoboxmain2">
            {activeInput === "input1" &&
              " Enter your name. कृपया अपना नाम दर्ज करें "}
          </div>
        )}

        {activeInput && (
          <div className="infoboxmain3">
            {activeInput === "input2" && "Enter salary. वेतन दर्ज करें"}
          </div>
        )}

        {activeInput && (
          <div className="infoboxmain4">
            {activeInput === "input3" && "Enter age . आयु दर्ज करें  "}
          </div>
        )}
        {activeInput && (
          <div className="infoboxmain5">
            {activeInput === "input4" && (
              <div>
                <p>
                  SECTION 80C: Section 80C allows a maximum deduction of{" "}
                  <span>Rs 1.5 lakh</span> every year from the taxpayer's total
                  income. The benefit of this deduction can be availed by
                  Individuals and HUFs. It is important to note that the overall
                  limit, including the subsections for claiming a deduction, is
                  Rs 1.5 lakh, except for an additional deduction of Rs 50,000
                  allowed under section 80CCD(1b).
                </p>
                <p>
                  धारा 80 सी: करदाताओं की कुल आय से हर साल अधिकतम{" "}
                  <span>1.5 लाख</span> रुपये की कटौती की अनुमति देती है। इस
                  कटौती का लाभ व्यक्तियों और एचयूएफ द्वारा उठाया जा सकता है। यह
                  ध्यान रखना महत्वपूर्ण है कि कटौती का दावा करने के लिए उपधाराओं
                  सहित कुल सीमा 1.5 लाख रुपये है, धारा 80सीसीडी(1बी) के तहत
                  50,000 रुपये की अतिरिक्त कटौती की अनुमति को छोड़कर।
                </p>
              </div>
            )}
          </div>
        )}
        {activeInput && (
          <div className="infoboxmain6">
            {activeInput === "input5" && (
              <div>
                <p>
                  The deduction allowed under Section 80D is Rs 25,000 in a
                  financial year. In the case of senior citizens, the deduction
                  limit allowed is <span> Rs 50,000 </span>. Individual: An
                  individual can claim a deduction of up to Rs 25,000 for the
                  insurance of self, spouse, and dependent children.
                </p>
                <p>
                  धारा 80डी के तहत एक वित्तीय वर्ष में कटौती की अनुमति{" "}
                  <span> 25,000 </span>
                  रुपये है। वरिष्ठ नागरिकों के मामले में, कटौती की सीमा 50,000
                  रुपये है। व्यक्तिगत: एक व्यक्ति स्वयं, जीवनसाथी और आश्रित
                  बच्चों के बीमा के लिए 25,000 रुपये तक की कटौती का दावा कर सकता
                  है।
                </p>
              </div>
            )}
          </div>
        )}
        {activeInput && (
          <div className="infoboxmain7">
            {activeInput === "input6" && (
              <div>
                <p>
                  Donations under 80G should be made to local authority or
                  government which is used for any charitable purposes. Only{" "}
                  <span>10%</span>
                  of the Adjusted Gross Income becomes eligible for deductions.
                  However, an amount exceeding this limit will be capped at 10%.
                </p>
                <p>
                  80जी के तहत दान स्थानीय प्राधिकरण या सरकार को दिया जाना चाहिए
                  जिसका उपयोग किसी धर्मार्थ उद्देश्य के लिए किया जाता है।
                  समायोजित सकल आय का केवल<span>10%</span> ही कटौती के लिए पात्र
                  बनता है। हालाँकि, इस सीमा से अधिक की राशि पर 10% की सीमा तय की
                  जाएगी।
                </p>
              </div>
            )}
          </div>
        )}
        {activeInput && (
          <div className="infoboxmain8">
            {activeInput === "input7" && (
              <div>
                <p>
                  Section 80TTA of the Income Tax Act, 1961 allows a deduction
                  of up to <span>INR 10,000</span> on interest income from
                  savings in banks, cooperatives, or post offices. No deduction
                  is provided for fixed deposit interest.
                </p>
                <p>
                  आयकर अधिनियम, 1961 की धारा 80TTA बैंकों, सहकारी समितियों या
                  डाकघरों में बचत से ब्याज आय पर <span> 10,000 रुपये</span> तक
                  की कटौती की अनुमति देती है। सावधि जमा ब्याज के लिए कोई कटौती
                  प्रदान नहीं की जाती है।
                </p>
              </div>
            )}
          </div>
        )}
        {activeInput && (
          <div className="infoboxmain9">
            {activeInput === "input8" && (
              <div>
                <p>
                  Only
                  <span>
                    {" "}
                    interest paid on loans taken for higher education is
                    eligible
                  </span>{" "}
                  for deduction. Repayment of the principal portion of the loan
                  taken for higher education does not qualify for a deduction.
                </p>
                <p>
                  केवल{" "}
                  <span>
                    उच्च शिक्षा के लिए लिए गए ऋण पर भुगतान किया गया ब्याज
                  </span>{" "}
                  ही कटौती के लिए पात्र है। उच्च शिक्षा के लिए लिए गए ऋण के
                  मूलधन का पुनर्भुगतान कटौती के लिए योग्य नहीं है।
                </p>
              </div>
            )}
          </div>
        )}
        {activeInput && (
          <div className="infoboxmain10">
            {activeInput === "input9" && (
              <div>
                <p>
                  Property stamp value must be <span>below Rs 45 lakhs</span>.
                  Home loans sanctioned from April 1, 2019 to March 31, 2022,
                  qualify for benefits. Only individuals can claim deductions.
                  Loan must be obtained from a financial institution. Deduction
                  limit is Rs
                  <span>1.50 lakhs</span> per year.
                </p>
                <p>
                  संपत्ति का स्टांप मूल्य<span> 5 लाख </span> 4रुपये से कम होना
                  चाहिए। 1 अप्रैल, 2019 से 31 मार्च, 2022 तक स्वीकृत गृह ऋण लाभ
                  के लिए पात्र हैं। केवल व्यक्ति ही कटौती का दावा कर सकते हैं।
                  किसी वित्तीय संस्थान से ऋण प्राप्त करना होगा। कटौती की सीमा{" "}
                  <span>1.50 </span>
                  लाख रुपये प्रति वर्ष है.
                </p>
              </div>
            )}
          </div>
        )}
        {activeInput && (
          <div className="infoboxmain11">
            {activeInput === "input10" && (
              <div>
                <p>
                  NPS subscribers can get an extra deduction of up to{" "}
                  <span>Rs. 50,000</span>
                  under section 80CCD (1B) for investing in NPS Tier I account.
                  This is in addition to the existing Rs. 1.5 lakh deduction
                  under section 80C of the Income Tax Act, 1961.
                </p>
                <p>
                  एनपीएस ग्राहकों को एनपीएस टियर I खाते में निवेश के लिए धारा
                  80CCD (1B) के तहत <span> 50,000 </span>रुपये तक की अतिरिक्त
                  कटौती मिल सकती है। यह मौजूदा आयकर अधिनियम, 1961 की धारा 80 सी
                  के तहत 1.5 लाख की कटौती के अतिरिक्त है।
                </p>
              </div>
            )}
          </div>
        )}
      </div>

      {/* setting for popup functionality of tax on clicking submit */}
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        {/* <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button> */}
      </div>
      <Modal className="print-modal" show={showModal} onHide={handleClose}>
        <Modal.Body className="modalbody">
          <div className="modalbody">
            <Modal.Header>
              <Modal.Title>
                {" "}
                <div className="topmodal">
                  <h1> Income Tax calculator by</h1>
                  <h2>
                    <img src={marchclosing} alt="logo" width={100} />
                  </h2>
                </div>
              </Modal.Title>
            </Modal.Header>

            <div className="popuptable">
              <table className="table">
                <thead>
                  <tr>
                    <th>Regime</th>
                    <th>Old Regime</th>
                    <th>New Regime ({year})</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Taxable Income</td>
                    <td>₹ {finaltax1}</td>
                    <td>₹ {finaltax2}</td>
                  </tr>
                  <tr>
                    <td>Tax Amount</td>
                    <td>₹ {Math.round(tax)}</td>
                    <td>₹ {Math.round(tax1)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {taxDifference !== 0 && (
              <div className="suggestion">
                <h3>Suggestion by March Closing</h3>
                <p>
                  Consider opting for{" "}
                  {isOldRegimeBetter ? "the Old" : "the New"} Regime to pay ₹{" "}
                  {Math.round(taxDifference)} less in tax compared to{" "}
                  {isOldRegimeBetter ? "the New" : "the Old"} Regime.
                </p>

                <div className="modallink">
                  <p>
                    For filing returns do contact us.{" "}
                    <span>
                      <Link to={"/contactus"}>Click here</Link>
                    </span>{" "}
                  </p>
                </div>
              </div>
            )}

            <div className="printbutton">
              <button className="print-btn" onClick={handlePrint}>
                Get this printed
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Existing;
