import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./Tax.css";

function UserIncome() {
  const [income, setIncome] = useState(0);
  var [tax, setTax] = useState(0);
  var [nincome, setNincome] = useState();
  var [age, setAge] = useState();

  function Calculation() {
    if (age < 60) {
      if (income > 1500000) {
        //   tax here is 30 % above 1500000 plus 187000 inr
        tax =
          ((income - 1500000) * 30) / 100 +
          187500 +
          ((((income - 1500000) * 30) / 100 + 187500) * 4) / 100;
      } else if (income > 1250000) {
        tax = 1.04 * (((income - 1250000) * 25) / 100 + 125000); // tax here is 25 % plus 125000 inr ;;; multiplying with 1.04 to include 4%  cess on tax
      } else if (income > 1000000) {
        tax = 1.04 * (((income - 1000000) * 20) / 100 + 75000); // tax here is 20 % plus 75000 inr
      } else if (income > 750000) {
        tax = 1.04 * (((income - 750000) * 15) / 100 + 37500); // tax here is 15 % plus 37500 inr
      } else if (income > 500000) {
        tax = 1.04 * (((income - 500000) * 10) / 100 + 12500); // tax here is 10 % plus 12500 inr
      } else if (income > 250000) {
        tax = 1.04 * (((income - 250000) * 5) / 100); // tax here is 5 %
      } else {
        tax = 0;
      }
    }

    setTax(tax);

    nincome = income - tax;

    setNincome(nincome);
  }

  return (
    <div class="containernew">
      <h2>User income tax calculation according to new regime</h2>
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Select financial year
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="#/action-1">2023-2024</Dropdown.Item>
          <Dropdown.Item href="#/action-2">2022-2023</Dropdown.Item>
          <Dropdown.Item href="#/action-3">2021-2022</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <div class="form-group">
        <label>Employee Name</label>
        <input type="text" class="form-control" placeholder="Employee Name" />
      </div>
      <div class="form-group">
        <label>Income</label>
        <input
          type="text"
          class="form-control"
          placeholder="Enter Income"
          onChange={(event) => {
            setIncome(event.target.value);
          }}
        />
      </div>
      <div class="form-group">
        <label>Age</label>
        <input
          type="text"
          class="form-control"
          placeholder="Enter Age"
          onChange={(event) => {
            setAge(event.target.value);
          }}
        />
      </div>

      <button type="submit" onClick={Calculation} class="btn btn-primary mt-4">
        Submit
      </button>
      <div class="form-group">
        <label>Tax</label>
        <input type="text" class="form-control" placeholder="Tax" value={tax} />
      </div>
      <div>
        {" "}
        <p>
          {" "}
          Please note that these calculated taxes are according to new tax
          regimes directed by govt of India .
        </p>
        <p> The calculated tax includes 4% cess applicable on the tax .</p>
      </div>
      <div class="form-group4">
        <label>Net Income</label>
        <input
          type="text"
          class="form-control"
          placeholder="Net Income"
          value={nincome}
        />
      </div>
    </div>
  );
}

export default UserIncome;
