import Container from "react-bootstrap/Container";
import "./rent.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import marchclosing from "../images/marchclosing.png";
import { useState } from "react";
import Banner from "./Banner";
function Rent() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  let [rent, setRent] = useState("");
  const [owner, setOwner] = useState("");
  const [pan, setPan] = useState("");
  const [showSlips, setShowSlips] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [slipType, setSlipType] = useState("monthly");

  if (slipType === "quarterly") {
    rent = 3 * rent;
  }
  //for regex
  const nameRegex = /^[a-zA-Z\s]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const rentRegex = /^\d+$/;
  // const panRegex = /^[A-Z]{5}\d{4}[A-Z]{1}$/;
  const handleSlipTypeChange = (event) => {
    setSlipType(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!nameRegex.test(name)) {
      alert("Please enter a valid name.");
      return;
    }

    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (!rentRegex.test(rent)) {
      alert("Please enter a valid monthly rent.");
      return;
    }

    if (owner === "") {
      alert("Please enter the owner's name.");
      return;
    }

    if (address === "") {
      alert("Please enter the address.");
      return;
    }

    // if (!panRegex.test(pan)) {
    //   alert("Please enter a valid PAN number.");
    //   return;
    // }

    if (startDate === null || endDate === null) {
      alert("Please select a start date and end date.");
      return;
    }

    if (startDate >= endDate) {
      alert("The start date must be before the end date.");
      return;
    }

    // if all validations pass, submit the form
    console.log("Submitting form...");

    console.log({
      email,
      name,
      rent,
      address,
      owner,
      startDate,
      endDate,
    });
    setShowSlips(true);
  };

  const renderRentSlips2 = () => {
    if (!startDate || !endDate) {
      return null;
    }
    const startMonth = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      1
    );
    const endMonth = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
    const months = [];
    if (slipType === "monthly") {
      for (
        let month = new Date(startMonth);
        month <= endMonth;
        month.setMonth(month.getMonth() + 1)
      ) {
        months.push(new Date(month));
      }
    } else if (slipType === "quarterly") {
      for (
        let month = new Date(startMonth);
        month <= endMonth;
        month.setMonth(month.getMonth() + 3)
      ) {
        const quarterStartMonth = new Date(month);
        const quarterEndMonth = new Date(
          month.getFullYear(),
          month.getMonth() + 2,
          1
        );
        months.push({ start: quarterStartMonth, end: quarterEndMonth });
      }
    }
    return (
      <Row className="sliprent">
        {months.map((quarter) => (
          <Col key={quarter.start.getTime()} md={12} className="mb-3">
            <div className="rent-slip">
              <h3>
                {name}'s Rent Slip for{" "}
                {quarter.start.toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                })}{" "}
                -{" "}
                {quarter.end.toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                })}{" "}
                by <img src={marchclosing} alt="logo" width={100} />
              </h3>
              <div className="slip">
                <p>
                  {" "}
                  <span>Owner Name: </span>
                  {owner}
                </p>
                {pan && (
                  <p>
                    <span>PAN:</span> {pan}
                  </p>
                )}
              </div>

              <div className="slip">
                <p>
                  <span>Rent:</span> {rent}
                </p>

                <p>
                  <span>Duration:</span>{" "}
                  {quarter.start.toLocaleDateString("en-US", {
                    month: "long",
                    year: "numeric",
                  })}{" "}
                  -{" "}
                  {quarter.end.toLocaleDateString("en-US", {
                    month: "long",
                    year: "numeric",
                  })}
                </p>
              </div>

              <h6>
                <span>Address:</span>
                {address}
              </h6>
              <h5>
                Amount received from {name} as rent for the period from{" "}
                {quarter.start.toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                })}{" "}
                to{" "}
                {quarter.end.toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                })}{" "}
                is {rent}
              </h5>
              <p>
                <span>Signed by</span> .....................
              </p>
            </div>
          </Col>
        ))}
        <div className="rentbutton1">
          <Button
            className="printrent"
            variant="outline-danger"
            onClick={() => window.print()}
          >
            Print Rent Slips
          </Button>
        </div>
      </Row>
    );
  };

  const renderRentSlips = () => {
    if (!startDate || !endDate) {
      return null;
    }
    const startMonth = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      1
    );
    const endMonth = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
    const months = [];
    if (slipType === "monthly") {
      for (
        let month = new Date(startMonth);
        month <= endMonth;
        month.setMonth(month.getMonth() + 1)
      ) {
        months.push(new Date(month));
      }
    } else if (slipType === "quarterly") {
      for (
        let month = new Date(startMonth);
        month <= endMonth;
        month.setMonth(month.getMonth() + 3)
      ) {
        months.push(new Date(month));
      }
    }
    return (
      <Row className="sliprent">
        {months.map((month) => (
          <Col key={month.getTime()} md={12} className="mb-3">
            <div className="rent-slip">
              <h3>
                {name}'s Rent Slip for{" "}
                {month.toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                })}{" "}
                by <img src={marchclosing} alt="logo" width={100} />
              </h3>
              <div className="slip">
                <p>
                  {" "}
                  <span>Owner's Name: </span>
                  {owner}
                </p>
                {pan && (
                  <p>
                    <span>PAN:</span> {pan}
                  </p>
                )}
              </div>

              <div className="slip">
                <p>
                  <span>Rent:</span> {rent}
                </p>

                <p>
                  <span>Month:</span>{" "}
                  {month.toLocaleDateString("en-US", {
                    month: "long",
                    year: "numeric",
                  })}
                </p>
              </div>

              <h6>
                <span>Address:</span>
                {address}
              </h6>
              <h5>
                Amount received from {name} as rent for the month of{" "}
                {month.toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                })}{" "}
                is {rent}
              </h5>
              <p>
                <span>Signed by</span> .....................
              </p>
            </div>
          </Col>
        ))}
        <div className="rentbutton1">
          <Button
            className="printrent"
            variant="outline-danger"
            onClick={() => window.print()}
          >
            Print Rent Slips
          </Button>
        </div>
      </Row>
    );
  };
  return (
    <div className="main-rent">
      <Banner
        h1Content="  Easiest way to get rent"
        h1Span={<span style={{ color: "#dd3333" }}> Receipts.</span>}
        h1Content2=""
        h1Span2={<span style={{ color: "#dd3333" }}></span>}
        pageDescription="Rent slips made easy - generate and organize with ease!"
      />

      <Container className="rent">
        <Row className="renstlipboxupdated">
          <Col xs={12} lg={8} md={8}>
            <h1>
              Generate <span style={{ color: "#dd3333" }}>FREE</span> Rent
              Receipts
            </h1>
            <ul>
              <li>Avoid last minute hassle of arranging rent receipts</li>
              <li>Fill the required details</li>
              <li>
                {" "}
                <span style={{ color: "#dd3333" }}> Print</span> the receipt in
                PDF Format
              </li>
              <li>Get the receipt signed from your landlord</li>
              <li>Submit it to your company's HR department</li>
            </ul>
          </Col>
          <Col xs={12} lg={4} md={4} className="FORMRENT">
            {/* <h1> Your FORM for generating rent receipt</h1> */}
            <Form>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="Name"
                    placeholder=""
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridAddress1">
                  <Form.Label>Monthly Rent</Form.Label>
                  <Form.Control
                    placeholder=""
                    value={rent}
                    onChange={(e) => setRent(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridAddress2">
                  <Form.Label>Owner's name</Form.Label>
                  <Form.Control
                    placeholder=""
                    value={owner}
                    onChange={(e) => setOwner(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridCity">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    placeholder=""
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridAddress2">
                  <Form.Label>PAN Number</Form.Label>
                  <Form.Control
                    placeholder=""
                    value={pan}
                    onChange={(e) => setPan(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="slipType">
                  <Form.Label>Select slip type:</Form.Label>
                  <Form.Control
                    as="select"
                    name="slipType"
                    value={slipType}
                    onChange={handleSlipTypeChange}
                  >
                    <option value="monthly">Monthly</option>
                    <option value="quarterly">Quarterly</option>
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridStartDate">
                  <Form.Label>Start Date</Form.Label>
                  <br />
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEndDate">
                  <Form.Label>End Date</Form.Label>
                  <br />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </Form.Group>
              </Row>
              <div className="rentbuttondiv">
                <Button
                  className="rentbutton"
                  variant="primary"
                  type="button"
                  onClick={handleSubmit}
                >
                  Get Receipts
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
        {showSlips &&
          (slipType === "monthly"
            ? renderRentSlips()
            : slipType === "quarterly"
            ? renderRentSlips2()
            : null)}
      </Container>
    </div>
  );
}
export default Rent;
