import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import "./dashboard.css";
import { FaSun, FaMoon } from "react-icons/fa";
import { Link } from "react-router-dom";
import expert from "../images/expert.png";

const QueryTileList = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 520);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 520);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [queries, setQueries] = useState([]);
  const [filter, setFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [queryType, setQueryType] = useState("Personal");
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [queryTitle, setQueryTitle] = useState("");
  const [queryDescription, setQueryDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [personalRequestType, setPersonalRequestType] = useState("");
  const [businessRequestType, setBusinessRequestType] = useState("");
  const authToken = localStorage.getItem("accessToken");
  const userId = localStorage.getItem("UserID");
  const resolvedCount = queries.filter(
    (query) => query.currentStatus === "Resolved"
  ).length;
  const pendingCount = queries.filter(
    (query) => query.currentStatus === "Pending"
  ).length;

  const handleQueryTypeChange = (event) => {
    const newQueryType = event.target.value;

    if (newQueryType !== queryType) {
      setQueryType(newQueryType);
      setQueryTitle("");
      setQueryDescription("");
    }
  };

  useEffect(() => {
    axios
      .post(
        "https://marchclosing.com/api/oauth/v1/userinfo",
        {},
        {
          headers: {
            Authorization: `bearer ${authToken} ${userId}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        const UserDetail = response.data.UserDetail;
        if (UserDetail && UserDetail.fname) {
          setFirstName(UserDetail.fname);
        }
      })
      .catch((error) => {
        console.error("Error fetching user info:", error);
      });

    axios
      .get("https://marchclosing.com/api/oauth/v1/request/status", {
        headers: {
          Authorization: `bearer ${authToken} ${userId}`,
        },
      })
      .then((response) => {
        setQueries(response.data);
      })
      .catch((error) => {
        console.error("Error fetching queries:", error);
      });
  }, [authToken, userId]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const filteredQueries =
    filter === "all"
      ? queries
      : queries.filter((query) => query.currentStatus === filter);

  const searchedQueries = filteredQueries.filter(
    (query) =>
      query.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      query.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const queriesPerPage = 5;
  const indexOfLastQuery = currentPage * queriesPerPage;
  const indexOfFirstQuery = indexOfLastQuery - queriesPerPage;
  const currentQueries = searchedQueries.slice(
    indexOfFirstQuery,
    indexOfLastQuery
  );

  const totalPages = Math.ceil(searchedQueries.length / queriesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSubmit = () => {
    if (!queryDescription || !queryTitle || !queryType) {
      alert("Please enter all details");
      return;
    }
    const currentDate = new Date();
    const queryData = {
      title: queryTitle,
      description: queryDescription,
      type: queryType,
      currentStatus: "Pending",
      createdDate: currentDate.toLocaleDateString(),
    };
    window.location.reload(true);
    setIsLoading(true);

    console.log(queryData);

    axios
      .post("https://marchclosing.com/api/oauth/v1/request/set", queryData, {
        headers: {
          Authorization: `bearer ${authToken} ${userId}`,
        },
      })
      .then((response) => {
        console.log("Query created successfully!");
        const updatedQueries = [...queries, response.data];
        setQueries(updatedQueries);
        setIsLoading(false);
        sendMessage(response.data);
        window.location.reload(true);
        closeCreateForm();
      })
      .catch((error) => {
        console.error("Error creating query:", error);
        setIsLoading(false);
      });
  };

  const header = {
    headers: {
      Authorization:
        "Bearer EAAsTs1EjiT8BAFvh1oaZBmRA5ZBEzNtfW8NvsGYRvrZCi0sKBP6ZAR8IudZBCtq6j2qp0DU6n5OeOh7QsSkGM8ZC2LmZAhwzyFIx69kH2PfPGHkLQxC9GPJJAp8K1y5jsDohGN5okJesVYgB5TZBgv9kEkvEFZCOGgo2Wz7ESXftYer2E2Vs2AhNxQ1LdzaulacJOBkkDnNrvMAZDZD",
      Accept: "application/json",
    },
  };

  const sendMessage = () => {
    const body = {
      messaging_product: "whatsapp",
      to: "91" + 8872649119,
      type: "template",
      template: {
        name: "hello_world",
        language: { code: "en_US" },
      },
    };

    axios
      .post(
        "  https://graph.facebook.com/v17.0/100320906470290/messages",
        body,
        header
      )
      .then((res) => console.log("sent successfully", res))
      .catch((err) => console.log("error while sending", err));
  };

  const openCreateForm = () => {
    setShowCreateForm(true);
  };

  const closeCreateForm = () => {
    setShowCreateForm(false);
    setQueryTitle("");
    setQueryDescription("");
    setQueryType("Personal");
  };

  const maxWords = 5;
  const currentHour = new Date().getHours();
  let timeOfDay;
  let icon;
  if (currentHour >= 6 && currentHour < 18) {
    icon = <FaSun />;
  } else {
    icon = <FaMoon />;
  }

  if (currentHour >= 6 && currentHour < 12) {
    timeOfDay = "Morning";
  } else if (currentHour >= 12 && currentHour < 16) {
    timeOfDay = "Afternoon";
  } else {
    timeOfDay = "Evening";
  }

  return (
    <div className="dashboardbox">
      {isLoading && <div className="loader">Loading...</div>}
      <Container className="container1">
        <Row className="namerowdash" xs={12} lg={8} md={8}>
          <h2>
            {" "}
            Good {timeOfDay} {icon}{" "}
          </h2>
          <h3>
            {" "}
            Welcome <span>{firstName}</span>. This is your personalized
            dashboard
          </h3>
        </Row>

        <Row xs={12} lg={8} md={8}>
          <div className="widgets1">
            <div className="button-container">
              <button className="querybutton" onClick={openCreateForm}>
                New Request<span>&#43;</span>
              </button>
            </div>
          </div>
        </Row>
        <Row className="row1dash" xs={12} lg={8} md={8}>
          <Col className="statscol">
            <h3> Total requests made : {queries.length}</h3>{" "}
          </Col>
          <Col className="statscol">
            <h3>Pending: {pendingCount}</h3>
          </Col>
          <Col className="statscol">
            <h3>Resolved:{resolvedCount} </h3>
          </Col>
        </Row>
        <Row xs={12} lg={8} md={8}>
          <Row xs={12} lg={10} md={10}>
            <Col lg={9} md={12} xs={12}>
              <Row xs={12} lg={8} md={8}>
                <div className="widgets">
                  <div className="filter-container">
                    <label htmlFor="filter">Filter by status:</label>
                    <select
                      id="filter"
                      value={filter}
                      onChange={handleFilterChange}
                    >
                      <option value="all">All</option>
                      <option value="Pending">Pending</option>
                      <option value="solved">Solved</option>
                    </select>
                  </div>
                  <div className="search-container">
                    <label htmlFor="search">Search queries:</label>
                    <input
                      type="text"
                      id="search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      placeholder=""
                    />
                  </div>
                </div>
              </Row>
              {currentQueries.length > 0 ? (
                <div className="newtablediv">
                  {isMobile ? (
                    currentQueries.map((query, index) => (
                      <div key={query.ticket} className="tr">
                        <div className="th">
                          {(currentPage - 1) * queriesPerPage + index + 1}
                        </div>

                        <div className="th">Title</div>
                        <div className="td">{query.title}</div>
                        <div className="th">Created Date</div>
                        <div className="td">{query.createdDate}</div>
                        <div className="th">Type</div>
                        <div className="td">{query.type}</div>
                        <div className="th">Current Status</div>
                        <div className="td">
                          <span
                            className={
                              query.currentStatus === "Pending"
                                ? "Pending"
                                : "Resolved"
                            }
                          >
                            {query.currentStatus}
                          </span>
                        </div>
                        <div className="th">Description</div>
                        <div className="td1">
                          {query.description
                            .split(" ")
                            .slice(0, maxWords)
                            .join(" ")}
                          {query.description.split(" ").length > maxWords &&
                            "..."}{" "}
                          <Link to={`/query/${query.ticket}`}>
                            More details <span>&#10132;</span>
                          </Link>
                        </div>
                      </div>
                    ))
                  ) : (
                    <table className="dashtable">
                      <thead>
                        <tr className="tr">
                          <th className="th">SrNo.</th>
                          <th className="th">Title</th>
                          <th className="th">Created Date</th>
                          <th className="th">Type</th>
                          <th className="th">Current Status</th>
                          <th className="th">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentQueries.map((query, index) => (
                          <tr className="tr" key={query.ticket}>
                            <td className="tr">
                              {(currentPage - 1) * queriesPerPage + index + 1}
                            </td>
                            <td className="tr">{query.title}</td>
                            <td className="tr">{query.createdDate}</td>
                            <td className="tr">{query.type}</td>
                            <td className="tr">
                              <span
                                className={
                                  query.currentStatus === "Pending"
                                    ? "Pending"
                                    : "Resolved"
                                }
                              >
                                {query.currentStatus}
                              </span>
                            </td>
                            <td className="tr1">
                              {query.description
                                .split(" ")
                                .slice(0, maxWords)
                                .join(" ")}
                              {query.description.split(" ").length > maxWords &&
                                "..."}{" "}
                              <Link to={`/query/${query.ticket}`}>
                                More details <span>&#10132;</span>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              ) : (
                <p>No queries found.</p>
              )}
              {searchedQueries.length > queriesPerPage && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </Col>
            {!isMobile && (
              <Col className="rightboxuser" lg={3} md={6} xs={12}>
                <div className="topbuttondash">
                  <button className="topbuttonbox">
                    Best tax saving plans
                  </button>
                </div>
                <h2>Personal ITR services from house of experts</h2>
                <p>
                  Maximize your tax savings with expert assistance! March
                  Closings offers top-notch ITR services backed by experienced
                  professionals. Get personalized guidance and file your taxes
                  with confidence. Claim your deductions and optimize your
                  returns today!
                </p>
                <h5> Plans from ₹ 399</h5>
                <div className="rightboxdiv">
                  <button className="buttonexp"> Get Started </button>
                </div>
                <div className="rightboxusericon">
                  <img alt="icon" src={expert} width={80} />
                </div>
              </Col>
            )}
          </Row>
        </Row>
      </Container>
      {showCreateForm && (
        <div className="dialog-overlay">
          <div className="maindialog">
            <div className="dialog-header">
              <h2>Create New Request</h2>
            </div>
            <div className="dialog">
              <label className="label1">Categories</label>
              <select
                className="inputinfo"
                name="requestType"
                required
                value={queryType}
                onChange={(event) => handleQueryTypeChange(event)}
              >
                <option value="">Select Category</option>
                <option value="business">Business</option>
                <option value="personal">Personal</option>
              </select>

              {queryType === "personal" && (
                <div>
                  <label className="label1">Personal Request Type</label>
                  <select
                    className="inputinfo"
                    name="personalRequestType"
                    required
                    value={personalRequestType}
                    onChange={(event) =>
                      setPersonalRequestType(event.target.value)
                    }
                  >
                    <option value="">Select Personal Request Type</option>
                    <option value="itrFiling">ITR filing</option>
                    <option value="accountsHelp">Accounts Help</option>
                    <option value="taxationHelp">Taxation Help</option>
                  </select>
                </div>
              )}

              {queryType === "business" && (
                <div>
                  <label className="label1">Business Request Type</label>
                  <select
                    className="inputinfo"
                    name="businessRequestType"
                    required
                    value={businessRequestType}
                    onChange={(event) =>
                      setBusinessRequestType(event.target.value)
                    }
                  >
                    <option value="">Select Business Request Type</option>
                    <option value="eInvoicing">E-Invoicing</option>
                    <option value="gstRegistration">GST Registration</option>
                    <option value="accountancy">Accountancy</option>
                  </select>
                </div>
              )}

              <label className="label1">Title</label>
              <input
                className="inputinfo"
                type="text"
                name="title"
                autoComplete="off"
                required
                value={queryTitle}
                onChange={(event) => setQueryTitle(event.target.value)}
              />
              <label className="label1">Description</label>
              <input
                className="textarea1"
                name="description"
                autoComplete="off"
                required
                value={queryDescription}
                onChange={(event) => setQueryDescription(event.target.value)}
              />

              <div className="dialog-buttons">
                <button onClick={handleSubmit} className="submit-button">
                  Submit
                </button>
                <button onClick={closeCreateForm} className="cancel-button">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((pageNumber) => (
          <li
            key={pageNumber}
            className={`page-item ${
              pageNumber === currentPage ? "active" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default QueryTileList;
