import React from "react";
import "./banner.css";
function Banner(props) {
  return (
    <div className="taxbanner">
      <h1>
        {props.h1Content} {props.h1Span} {props.h1Content2} {props.h1Span2}
      </h1>
      <h4>{props.pageDescription}</h4>
    </div>
  );
}

export default Banner;
