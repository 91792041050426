import React from "react";
import "./gstCalculatorTile.css";

const CalculatorAmountTile = (props) => {
    const GST_flipcards=[
        {
            id:'c1',
            title:'Taxable Amount',
            gstPercent:'0',
            taxable_amount:props.totalCost,
            widths:((props.totalCost/props.finalAmount)*100)+'%'
        },
        {
            id:'c4',
            title:'Tax Amount',
            gstPercent:props.gst,
            taxable_amount:props.gstAmount,
            widths:((props.gstAmount/props.finalAmount)*100)+'%'
        },
        {
            id:'c2',
            title:'CGST',
            gstPercent:(props.gst)/2,
            taxable_amount:(props.gstAmount)/2,
            widths:(((props.gstAmount)/2/props.gstAmount)*100)+'%'
        },
        {
            id:'c3',
            title:'SGST',
            gstPercent:(props.gst)/2,
            taxable_amount:(props.gstAmount)/2,
            widths:(((props.gstAmount)/2/props.gstAmount)*100)+'%'
        },
    ]

    const cards=GST_flipcards.map(card=><div class="card">
    <div class="title">
      
      <p class="title-text">{card.title}</p>
      <p class="percent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1792 1792"
          fill="currentColor"
          height="20"
          width="20"
        >
          <path d="M1408 1216q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z"></path>
        </svg>{" "}
        {card.gstPercent}%
      </p>
    </div>
    <div class="data">
      <p>₹ {card.taxable_amount}</p>

      <div class="range">
        <div class="fill" style={{width:card.widths}}></div>
      </div>
    </div>
  </div>)
  return (<>
    <div className="CalculatorAmountTile">
      {cards}
    </div>
  </>
  );
};

const GSTqueries=()=>{
const queries=[
    {
        id:'q1',
        ques:'What is GST?',
        ans:'The GST, or Goods and Services Tax, is an indirect tax system implemented in various countries, including India. It is a comprehensive tax levied on the supply of goods and services at each stage of production and distribution. The GST aims to simplify the taxation process by replacing multiple indirect taxes such as excise duty, service tax, and value-added tax (VAT). It is designed to be a destination-based tax, meaning it is levied at the final point of consumption.'
    },
    {
        id:'q1',
        ques:'What are different tax heads under GST?',
        ans:"The Goods and Services Tax (GST) system consists of different tax components. These components include the Central Goods and Services Tax (CGST), levied by the central government on intra-state supplies. The State Goods and Services Tax (SGST) is imposed by state governments on intra-state supplies. The Integrated Goods and Services Tax (IGST) is charged by the central government on inter-state supplies. Additionally, the Union Territory Goods and Services Tax (UTGST) is levied by the central government on intra-union territory supplies. These tax components collectively form the framework of the GST system, ensuring the appropriate taxation of goods and services within and across states and union territories."
    },
    {
        id:'q1',
        ques:'What is GST calculator?',
        ans:"A GST calculator is a convenient online tool used to calculate the GST amount payable for a specific period, such as a month or quarter. It can be utilized by various parties, including buyers, manufacturers, and wholesalers."
    },
    {
        id:'q1',
        ques:'Advantages of GST Calculator',
        ans:"The simplified GST calculator assists in determining the gross or net product price based on the applicable GST percentage. It provides a breakdown of the tax rate between CGST and SGST or accurately calculates IGST. By using a GST calculator, one can save time and reduce the likelihood of human errors when computing the total cost of goods and services."
    },

]
const cards=queries.map(card=><div className="GSTQuery"><h1>{card.ques}</h1><p>{card.ans}</p><hr/></div>)
return(<div className="GSTQueries">
<div className="InnerGSTQueries">Frequently Asked Questions
    {cards}
    <div className="GSTQuery">
    <h1>How to Calculate GST on March Closing Calculator</h1>
    <p>You can calculate the GST in Just 3 simple Steps:<ul>
        <li><b>STEP 1:</b> Select the Type of GST calculation you want (Inclusive or Exclusive)</li>
        <li><b>STEP 2:</b> Enter the Net price of Service or Goods</li>
        <li><b>STEP 2:</b> Enter the GST applicable that Service or Goods</li>
        </ul></p>
    </div>
</div>
</div>)
}



export {CalculatorAmountTile,GSTqueries};


