import React from "react";

const businessdata = [
  {
    id: "p1",

    heading: "GST Registraion / GST Return Filing",
    desc: " We specialize in providing comprehensive support for all your GST filing needs, we can help you streamline your GST filing process and keep your business running smoothly.",

    img: require("../images/GST.png"),
  },
  {
    id: "p2",

    heading: "Company incorporation / Annual Filings",
    desc: "    We offer annual filing assistance to ensure that your business stays compliant with all regulatory requirements and provide peace of mind and support for businesses of all sizes.</p>",

    img: require("../images/taxpay.png"),
  },
  {
    id: "p3",

    heading: "Audit Reports",
    desc: " Our website provides professional audit report assistance services to help businesses achieve financial transparency and accuracy.",

    img: require("../images/phone-call.png"),
  },
  {
    id: "p3",

    heading: "Talk to CA",
    desc: "  Gain a deeper understanding of your financial standing and explore new opportunities for growth with the help of a trusted professional.",

    img: require("../images/phone-call.png"),
  },
];

const Businessinfo = () => {
  const businesslist = businessdata.map((Businessinfo) => (
    <div className="imageflex">
      <div className="icon">
        <img src={Businessinfo.img} alt="Logo" width={35} />
      </div>

      <div className="left">
        <h4>{Businessinfo.heading}</h4>
        <p>{Businessinfo.desc}</p>
      </div>
    </div>
  ));
  return <>{businesslist}</>;
};
export default Businessinfo;
