import React from "react";
import { Link } from "react-router-dom";
import "./homecards.css";
import img from "../../images/tax.png";
const Flipcard = () => {
  const heading = "Hassle free ITR filing";

  return (
    <div className="productnewdiv">
      <Link to="/auth?mode=login" style={{ textDecoration: "none" }}>
        <div class="flip-card2">
          <div className="new1card">
            {" "}
            <h2> New</h2>
          </div>
          <img src={img} alt="Logo" width={90} />
          <h1>{heading}</h1>
          <h3> Assisted by our top experts </h3>
          <h3>
            Create a new account to <span>start</span>
          </h3>
        </div>
      </Link>
    </div>
  );
};

export default Flipcard;
