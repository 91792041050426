import React from "react";
const tenetizer=require("../images/Tenetizer.png")
const HomeFooter=()=>{
    return (<div className="HomeFooter">
        <div className="footerData">
            <p>March Closing is a platform that offers taxation and financial solutions to individuals, businesses, organizations, and chartered accountants in India. With a customer base of over 1.5 million happy customers, including 20,000+ CAs and tax experts, and serving over 10,000 businesses across the country, March Closing provides comprehensive services.
            <br/><br/>One of the key features of March Closing is the easy e-filing of Income Tax Returns (ITR). Users can simply upload their Form 16, claim deductions, and receive their acknowledgment number online. The platform supports e-filing of income tax returns for various income sources such as salary, house property, capital gains, business and profession, and other sources. Additionally, users can utilize March Closing to file TDS returns, generate Form-16, use the Tax Calculator software, claim HRA (House Rent Allowance), check refund status, and generate rent receipts for Income Tax Filing.
            <br/><br/>For chartered accountants, tax experts, and businesses, March Closing provides GST (Goods and Services Tax) software to ensure GST compliance. The software facilitates the management of returns and invoices in a user-friendly manner. Moreover, March Closing offers a comprehensive Goods and Services Tax course that includes tutorial videos, guides, and expert assistance, aiding users in mastering the intricacies of GST. Additionally, the platform can assist businesses in getting registered for Goods & Services Tax Law.
            <br/><br/>March Closing emphasizes the importance of saving taxes and provides expert guidance to help individuals and businesses optimize their tax planning.
            <br/>March Closing is a one-stop platform that caters to the taxation and financial needs of individuals, businesses, organizations, and chartered accountants in India. From e-filing Income Tax Returns to ensuring GST compliance and offering expert tax-saving guidance, March Closing provides comprehensive solutions to its diverse user base.</p>
        </div>
        <hr/>
        <div className="Product-Developer">
        MARCH CLOSING IS A PRODUCT BY <img src={tenetizer} alt="tenetizer"></img> (TENETIZER TECHNOLOGIES).
        </div>
    </div>)
}

export default HomeFooter;