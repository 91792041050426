import React from "react";
import { Link } from "react-router-dom";
import exchangeRupee from "../images/TAX.jpg";

const data_flipcards = [
  {
    id: "p1",
    link: "/tax",
    heading: "Taxation",
    desc: "Fastest income tax calculator",
    btn: "Calculate your tax",
    img: exchangeRupee,
  },
  {
    id: "p2",
    link: "/contactus",
    heading: "E-Invoicing",
    desc: "Soon a business requirement",
    btn: "Check Solution",
    img: require("../images/invoice.jpg"),
  },
  {
    id: "p3",
    link: "/gst",
    heading: "GST",
    desc: "Best GST solution providers",
    btn: "Find out",
    img: require("../images/GST.jpeg"),
  },
];

const flipcard = () => {
  const cards = data_flipcards.map((card) => (
    <Link to={card.link} style={{ textDecoration: "none" }}>
      <div class="flip-card">
        <img src={card.img} alt="Logo" />
        <h1>{card.heading}</h1>
        <p>{card.desc} </p>
        <p3> {card.btn} </p3>
      </div>
    </Link>
  ));
  return <>{cards}</>;
};

export default flipcard;
