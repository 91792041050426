import React from "react";
// import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

function NewSignup2(props) {
  // const navigate = useNavigate();
  //   const [showNewPage, setShowNewPage] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [fname, setFname] = useState("");
  let [emailerr, setEmailerr] = useState("");
  let [passwderr, setPasswderr] = useState("");
  let [fnameerr, setFnameerr] = useState("");

  let reRef = useRef(null);

  function handlePassChange(event) {
    setPassword(event.target.value);
    setPasswderr("");
  }
  function handleEmailChange(event) {
    setUsername(event.target.value.toLowerCase());
    setEmailerr("");
  }
  function handleNameChange(event) {
    setFname(event.target.value);
    setFnameerr("");
  }

  async function handleSubmitFOrm(event) {
    event.preventDefault();
    const ctoken = await reRef.current.executeAsync();
    let data = { fname, username, password, ctoken };
    const res = await fetch("https://marchclosing.com/api/oauth/v1/register", {
      method: "POST",
      // mode: "no-cors",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const data2 = await res.json();
    let entries = Object.entries(data2);
    if (data2.error !== undefined) {
      setPasswderr(entries[0][1].password);

      setEmailerr(entries[0][1].email);

      setFnameerr(entries[0][1].fname);
    } else {
      props.onSubmit(username);
    }
  }

  let button;
  if (!username || !password || !fname) {
    button = (
      <div className="btnnn">
        <button className="submitme2">REGISTER</button>
      </div>
    );
  } else {
    button = (
      <div className="btnnn">
        <button className="submitme" type="submit" onClick={handleSubmitFOrm}>
          REGISTER
        </button>
      </div>
    );
  }
  return (
    <>
      <ReCAPTCHA
        sitekey="6LcizEclAAAAACbd6POvWUG2H1nRuUxPXtQOfvBp"
        size="invisible"
        ref={reRef}
        onLoad={() => {}}
      ></ReCAPTCHA>
      <Form className="formcss">
        <div className="email box">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <label htmlFor="names">FULL NAME</label>
            <br></br>
            <input
              className="FormInput"
              type="text"
              required
              value={fname}
              placeholder="Enter your full name"
              id="names"
              onChange={handleNameChange}
            />
          </Form.Group>

          <div className="emailer error">{fnameerr}</div>
        </div>

        <div className="email box">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <label htmlFor="emails">EMAIL ADDRESS</label>
            <br></br>
            <input
              className="FormInput"
              type="email"
              required
              value={username}
              placeholder="Enter your account E-mail"
              id="emails"
              onChange={handleEmailChange}
            />
          </Form.Group>

          <div className="emailer error">{emailerr}</div>
        </div>
        <div className="password box">
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <label htmlFor="passwrd">PASSWORD</label>
            <br></br>
            <input
              className="FormInput"
              type="password"
              value={password}
              placeholder="Enter your secure password"
              onChange={handlePassChange}
              id="passwrd"
              required
            />
          </Form.Group>

          <div className="passworder error">{passwderr}</div>
        </div>
        {button}
      </Form>
      <div className="footerlogin">
        Already have an account?{" "}
        <a href={"?mode=login"} style={{ textDecoration: "none" }}>
          <span>Sign In</span>
        </a>
      </div>
    </>
  );
}

export default NewSignup2;
