import React from "react";
import {Chatbot} from "react-chatbot-kit";
import './chat.css'
import config from "../pages/chatbot/chatbotConfig";
import MessageParser from "../pages/chatbot/chatbotMessageParser";
import ActionProvider from "../pages/chatbot/ActionProvider";

const chatbot=()=>{
return (
<Chatbot config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}/>)
}

export default chatbot;
