import React from "react";
import { json} from "react-router-dom";
import {useSearchParams} from "react-router-dom";
import "./Authentication.css";
import {useState} from "react";

import NewSignup2 from "../pages/signup";
import LoginPage from "../pages/login";
import AfterSignup from "../pages/AfterSignup"
import ForgotPass from "../pages/ForgotPass";

function AuthenticationPage() {
    let [showNewPage, setShowNewPage] = useState(false);
    let [userEmail, setUserEmail] = useState('');
    const getData=(data)=>{
        setShowNewPage(true)
        setUserEmail(data)
    }
  const [searchParams] = useSearchParams();
  const isLogin = searchParams.get("mode") === "login";
  const isSignup = searchParams.get("mode") === "signup";
  const isforgotPass = searchParams.get("mode") === "forgotpassword";
  let getPage;
  let heading;
  if(isLogin===true){
    heading=<h1>Sign In</h1>;
    getPage=<LoginPage/>
  }
  else if(isSignup===true){
    heading=<h1>Sign Up</h1>;
    getPage=<NewSignup2 onSubmit={getData}/>
  }
  else if(isforgotPass===true){
    heading=<h1>Forgot Your Password</h1>
    getPage=<ForgotPass/>
  }
  else{
    heading=<h1 style={{paddingBottom:'45px'}}>Page Not found</h1>;
  }

  if (showNewPage && searchParams.get("mode") === "signup") {
    // render the new page
    return (
      <AfterSignup setmail={userEmail}/>
    );
  }

  return (
    <div className="main">
      
      <div className="contain">
        <div className={isSignup?"page":"page2"}>
          {/* <div className="logopart3">
          <img src={marchclosing} alt="logo" width={200} style={{alignSelf:"center"}}/>
          </div> */}
          <div className="signin box">
            {heading}
          </div>
          {getPage}
        </div>
      </div>
    </div>
  );
}

export default AuthenticationPage;

export async function action({ request }) {
  const searchParams = new URL(request.url).searchParams;
  const mode = searchParams.get("mode") || "login";
  console.log(mode)

  if (mode !== "login" && mode !== "signup") {
    throw json({ message: "Unsupported mode." }, { status: 422 });
  }

  const data = await request.formData();
  const authData = {
    email: data.get('emails'),
    password: data.get('passwrd'),
  };
  console.log(authData)
}
