import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Banner from "../Banner";
import "./gstcalculator.css";
// import marchclosing from "../../images/marchclosing.png";
import { CalculatorAmountTile, GSTqueries } from "./gstCalculatorTile";
const GSTCalculator = () => {
  const [amount, setAmount] = useState("");
  const [gstPercentage, setGstPercentage] = useState("");
  const [gstAmount, setGstAmount] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const [isGSTExclusive, setIsGSTExclusive] = useState(false);
  const [isGSTInclusive, setIsGSTInclusive] = useState(false);
  let gstcalcTile;
  if (isGSTInclusive === true) {
    gstcalcTile = (
      <CalculatorAmountTile
        totalCost={finalAmount}
        gst={gstPercentage}
        gstAmount={gstAmount}
        finalAmount={finalAmount + gstAmount}
      />
    );
  } else {
    gstcalcTile = (
      <CalculatorAmountTile
        totalCost={amount}
        gst={gstPercentage}
        gstAmount={gstAmount}
        finalAmount={finalAmount}
      />
    );
  }
  const calculateGST = () => {
    const amountValue = parseFloat(amount);
    const gstPercentageValue = parseFloat(gstPercentage);
    if (!isNaN(amountValue) && !isNaN(gstPercentageValue)) {
      if (isGSTExclusive) {
        const gstValue = (amountValue * gstPercentageValue) / 100;
        const finalAmountValue = amountValue + gstValue;
        setGstAmount(gstValue.toFixed(2));
        setFinalAmount(finalAmountValue.toFixed(2));
      } else if (isGSTInclusive) {
        const gstValue =
          (amountValue * gstPercentageValue) / (100 + gstPercentageValue);
        const finalAmountValue = amountValue - gstValue;
        setGstAmount(gstValue.toFixed(2));
        setFinalAmount(finalAmountValue.toFixed(2));
      } else {
        setGstAmount("");
        setFinalAmount("");
      }
    }
  };

  return (
    <div>
      <Banner
        h1Content="Calculation of "
        h1Span={<span style={{ color: "#dd3333" }}> GST</span>}
        h1Content2="made "
        h1Span2={<span style={{ color: "#dd3333" }}>easy</span>}
        pageDescription="Check out our GST calculator"
      />
      <Container className="gst-calculator">
        <Row>
          <Col>
            <Form.Group controlId="gstExclusive" className="checkgst">
              <Form.Check
                className="checkbox1"
                type="checkbox"
                label="GST Exclusive"
                checked={isGSTExclusive}
                onChange={(e) => {
                  setIsGSTExclusive(e.target.checked);
                  setIsGSTInclusive(false);
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="gstInclusive" className="checkgst">
              <Form.Check
                className="checkbox1"
                type="checkbox"
                label="GST Inclusive"
                checked={isGSTInclusive}
                onChange={(e) => {
                  setIsGSTInclusive(e.target.checked);
                  setIsGSTExclusive(false);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group as={Row} controlId="amount">
              <Form.Label column sm={4}>
                Amount (₹):
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group as={Row} controlId="gstPercentage">
              <Form.Label column sm={4}>
                GST Percentage:
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  type="number"
                  value={gstPercentage}
                  onChange={(e) => setGstPercentage(e.target.value)}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <div className="gstcalculatediv">
          <Button
            className="gstcalculatebutton"
            variant="primary"
            onClick={calculateGST}
          >
            Calculate
          </Button>
        </div>

        <Row className="result-row">
          <Col md={3}>
            <Form.Label>GST Amount (₹):</Form.Label>
          </Col>
          <Col md={6}>
            <Form.Control type="text" value={Math.round(gstAmount)} readOnly />
          </Col>
        </Row>
        <Row className="result-row">
          <Col md={3}>
            <Form.Label>Final Amount (₹):</Form.Label>
          </Col>
          <Col md={6}>
            <Form.Control
              type="text"
              value={Math.round(finalAmount)}
              readOnly
            />
          </Col>
        </Row>
      </Container>
      {gstcalcTile}
      <GSTqueries />
    </div>
  );
};

export default GSTCalculator;
