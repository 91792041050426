
class ActionProvider {
    constructor(
     createChatBotMessage,
     setStateFunc,
     createClientMessage,
     stateRef,
     createCustomMessage,
     ...rest
   ) {
     this.createChatBotMessage = createChatBotMessage;
     this.setState = setStateFunc;
     this.createClientMessage = createClientMessage;
     this.stateRef = stateRef;
     this.createCustomMessage = createCustomMessage;
   }

   greet=()=>{
    const message=this.createChatBotMessage("Did you find what you were looking for?",{widget:"options",});
    this.addMessageToState(message);
   }
   yesOption=()=>{
    const message1=this.createChatBotMessage("Thats Great :)");
    this.addMessageToState(message1);
    const message=this.createChatBotMessage("Are you interested in any of these services below?",{widget:"yesOptions",});
    this.addMessageToState(message);
   }
   noOption=()=>{
    const message=this.createChatBotMessage("Are you looking for any of the services below?",{widget:"noOptions",});
    this.addMessageToState(message);
   }
   IncomeOption=()=>{
    const message=this.createChatBotMessage("Are you looking for anything from below? Please choose from these,",{widget:"IncomeOptions",});
    this.addMessageToState(message);
   }
   HRAOption=()=>{
    const message=this.createChatBotMessage("Are you looking for anything from below? Please select from these,",{widget:"HRAOptions",});
    this.addMessageToState(message);
   }
   GSTOption=()=>{
    const message=this.createChatBotMessage("Are you looking for anything from below? Please select from these,",{widget:"GSTOptions",});
    this.addMessageToState(message);
   }

   addMessageToState=(message)=>{
    this.setState((prevState)=>({
      ...prevState,
      messages:[...prevState.messages,message],
    }))
   }
 }
 
 export default ActionProvider;