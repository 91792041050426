import React from "react";
const data_trusted = [
  {
    id: "i1",
    img: require("../images/Tenetizer.png"),
    width: 170,
    link: "https://tenetizer.com",
  },
  {
    id: "i2",
    img: require("../images/o2bazarr.png"),
    width: 80,
    link: "https://o2bazar.com/",
  },
];
const Trusted = () => {
  const cards = data_trusted.map((card) => (
    <div className="trustedimages">
      <a href={card.link} target="_blank" rel="noreferrer">
        <img src={card.img} alt="Logo" width={card.width} />
      </a>
    </div>
  ));
  return (
    <div className="trusted">
      <h4> Trusted by your favourites</h4>
      <div className="trusted0">
        <div className="trusted1">{cards}</div>
      </div>
    </div>
  );
};
export default Trusted;
