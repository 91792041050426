import React, { useState } from "react";

const InvoiceGenerator = () => {
  const [formData, setFormData] = useState({
    documentType: "",
    supplierName: "",
    supplierGSTIN: "",
    supplierAddress: "",
    supplierPlace: "",
    supplierState: "",
    supplierPincode: "",
    documentNumber: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const generateInvoice = () => {
    if (Object.values(formData).some((field) => field === "")) {
      alert("Please fill in all fields before generating the invoice.");
      return;
    }

    // Generate and display the invoice using formData
    console.log(formData);
  };

  return (
    <div>
      <h1>Invoice Generator</h1>
      <div>
        <label>Document Type Code:</label>
        <input
          type="text"
          name="documentType"
          value={formData.documentType}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Supplier Legal Name:</label>
        <input
          type="text"
          name="supplierName"
          value={formData.supplierName}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Supplier GSTIN:</label>
        <input
          type="text"
          name="supplierGSTIN"
          value={formData.supplierGSTIN}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Supplier Address:</label>
        <input
          type="text"
          name="supplierAddress"
          value={formData.supplierAddress}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Supplier Place:</label>
        <input
          type="text"
          name="supplierPlace"
          value={formData.supplierPlace}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Supplier State Code:</label>
        <input
          type="text"
          name="supplierState"
          value={formData.supplierState}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Supplier Pincode:</label>
        <input
          type="text"
          name="supplierPincode"
          value={formData.supplierPincode}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Document Number:</label>
        <input
          type="text"
          name="documentNumber"
          value={formData.documentNumber}
          onChange={handleInputChange}
        />
      </div>
      <button onClick={generateInvoice}>Generate Invoice</button>
    </div>
  );
};

export default InvoiceGenerator;
