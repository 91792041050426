import React from "react";
// import Newreg from "./Newreg";
import Existing from "./Existing";
// import { Link } from "react-router-dom";
import "./Tax.css";
import Banner from "./Banner";

function Tax() {
  return (
    <div className="main-tax">
      <Banner
        h1Content="Less"
        h1Span={<span style={{ color: "#dd3333" }}>Tax.</span>}
        h1Content2="More"
        h1Span2={<span style={{ color: "#dd3333" }}>Savings.</span>}
        pageDescription="Calculating tax was never so easy and fast"
      />
      <div className="calculator">
        <Existing />
      </div>
      <div className='outerTaxDesc'><p id="TaxHeading">How to Use Tax calculator ?</p>
      <div className="taxdesc">
        <h5>What is Income Tax Calculator?</h5>
        <p>
          The Income tax calculator is an easy-to-use online tool that helps you
          estimate your taxes based on your income after the Union Budget is
          presented. We have updated our tool in line with the income tax
          changes proposed in the Union Budget 2022-23.
        </p>

        <h5>
          How to use the Income tax calculator for FY 2022-23 (AY 2023-24)?
        </h5>

        <p>Following are the steps to use the tax calculator:</p>

        <p>
          <span>1.</span> Choose the financial year for which you want your
          taxes to be calculated.
        </p>
        <p>
          <span>2.</span> Select your age accordingly. Tax liability in India
          differs based on the age groups.
        </p>
        <p>
          <span>3.</span> Enter your salary exluding any deductions
        </p>
        <p>
          <span>4.</span> Enter all the deductions applicable to you.
        </p>

        <p>
          <span>5.</span> Along with taxable salary, you must enter other
          details such as interest income, rental income, interest paid on home
          loan for rented, and interest paid on loan for self occupied property.
        </p>
        <p>
          <span>6.</span> For Income from Digital Assets, enter the net income (
          Sale consideration less Cost of Acquisition), such income is taxed at
          30% Plus applicable surcharge and cess.
        </p>
        <p>
          <span>7.</span> In case, you want to calculate your taxes under the
          old tax slabs,you will have to enter your tax saving investments under
          section 80C, 80D, 80G, 80E and 80TTA.
        </p>
        <p>
          <span>8.</span> Click on 'Calculate' to get your tax liability.
        </p>
      </div>
      </div>
      
    </div>
  );
}

export default Tax;
