import React from "react";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ReCAPTCHA from "react-google-recaptcha";

function LoginPage() {
  // function setCookie (cname, cvalue, minutes) {
  //   console.log(minutes);
  //   var d = new Date();
  //   d.setTime(d.getTime() + minutes * 60 * 1000);
  //   var expires = "expires=" + d.toUTCString();
  //   console.log(expires);
  //   document.cookie = cname + "=" + cvalue + "; " + expires;
  // }

  const navigate = useNavigate();
  let reRef = useRef(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  let [emailerr, setEmailerr] = useState("");
  let [passwderr, setPasswderr] = useState("");

  async function test(event) {
    event.preventDefault();
    const ctoken = await reRef.current.executeAsync();
    let data = { ctoken };
    let url = `https://marchclosing.com/api/oauth/v1/token?username=${username}&password=${password}&grant_type=password`;
    // let url = `http://localhost:4000/oauth/v1/token?username=${username}&password=${password}&grant_type=password`;
    const res = await fetch(url, {
      method: "POST",
      // mode: "no-cors",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const data2 = await res.json();

    if (data2.accessToken) {
      localStorage.setItem("accessToken", data2.accessToken);
      localStorage.setItem("refreshToken", data2.refreshToken);
      localStorage.setItem("UserID", data2.userID);
      navigate("/");
    }
    if (data2.error) {
      if (data2.error === "Password Incorrect!") {
        setPasswderr(data2.error);
        setEmailerr("");
      } else if (data2.error === "User does not exist!") {
        setEmailerr(data2.error);
        setPasswderr("");
      } else {
        setEmailerr(data2.error);
        setPasswderr("");
      }
    }
  }
  let button;
  if (!username || !password) {
    button = (
      <div className="btnnn">
        <button className="submitme2">SIGN IN</button>
      </div>
    );
  } else {
    button = (
      <div className="btnnn">
        <button className="submitme" type="submit" onClick={test}>
          SIGN IN
        </button>
      </div>
    );
  }

  return (
    <>
      <ReCAPTCHA
        sitekey="6LcizEclAAAAACbd6POvWUG2H1nRuUxPXtQOfvBp"
        size="invisible"
        ref={reRef}
      ></ReCAPTCHA>
      <Form className="formcss">
        <div className="email box">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <label htmlFor="emails">EMAIL ADDRESS</label>
            <br></br>
            <input
              className="FormInput"
              type="email"
              required
              value={username}
              placeholder="Your account email"
              id="emails"
              onChange={(e) => {
                setUsername(e.target.value);
                setEmailerr("");
              }}
            />
          </Form.Group>

          <div className="emailer error">{emailerr}</div>
        </div>
        <div className="password box">
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <label htmlFor="passwrd">PASSWORD</label>
            <br></br>
            <input
              className="FormInput"
              type="password"
              value={password}
              placeholder="Enter your secure password"
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswderr("");
              }}
              id="passwrd"
              required
            />
          </Form.Group>

          <div className="passworder error">{passwderr}</div>
        </div>
        <div className="checkboxx">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Keep me logged in
            </label>
          </div>
        </div>
        <div className="forgotpass">
          <p>
            <a href={"?mode=forgotpassword"}>Forgot your Password?</a>
          </p>
        </div>
        {button}
      </Form>
      <div className="footerlogin">
        Don't have an account?{" "}
        <a href={"?mode=signup"} style={{ textDecoration: "none" }}>
          <span>Sign Up</span>
        </a>
      </div>
    </>
  );
}

export default LoginPage;
