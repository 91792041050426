import React from "react";

const servicesection = [
  {
    id: "p1",

    heading: "Personal ITR Filing Assistance",
    desc: "Our team of experts will guide you through the entire process of ITR filing, from gathering the required documents to submitting the final form.",

    img: require("../images/GST.png"),
  },
  {
    id: "p2",
    link: "/contactus",
    heading: "GST Filing Assistance",
    desc: "We specialize in providing comprehensive support for all your GST filing needs, ensuring that you remain compliantwith all regulatory requirements.</p>",

    img: require("../images/GST.png"),
  },
  {
    id: "p3",
    link: "/gst",
    heading: "Open a Company/Firm",
    desc: "We understand the challenges that entrepreneurs face and are committed to helping them navigate the complex process of starting and growing a business.",

    img: require("../images/taxpay.png"),
  },
  {
    id: "p4",
    heading: "Talk to CA",
    desc: "   Gain a deeper understanding of your financial standing and explore new opportunities for growth with the help of a trusted professional.",
    img: require("../images/phone-call.png"),
  },
];

const Serviceinfo = () => {
  const servicelist = servicesection.map((servicedata) => (
    <div className="imageflex">
      <div className="icon">
        <img src={servicedata.img} alt="Logo" width={35} />
      </div>

      <div className="left">
        <h4>{servicedata.heading}</h4>
        <p>{servicedata.desc}</p>
      </div>
    </div>
  ));
  return <>{servicelist}</>;
};
export default Serviceinfo;
