import React from "react";
import Contact from "./Contact";

function AboutUs() {
  return (
    <div>
      <Contact />
    </div>
  );
}

export default AboutUs;
