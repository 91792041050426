import React from "react";

const AfterSignup = (props) => {
  let getMail = props.setmail;
  const verifyAgain = async (event) => {
    const url = "https://marchclosing.com/api/oauth/v1/resend/verify";
    event.preventDefault();
    let data = { userName: getMail };
    await fetch(url, {
      method: "POST",
      // mode: "no-cors",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  };
  return (
    <div className="verificationpart">
      <div className="row">
        {/* <div className="logopart3">
            <img src={logoImage} alt="horse" width={100} />
          </div> */}

        <div className="verificationheading">
          <h2>Check Your Email</h2>
          <p> We have sent you a verification email</p>

          <p>
            You will be able to continue setting up your account here once you
            verify your email address.
          </p>
          <p>This email was sent to {getMail} </p>

          {/* <h4> Change Email</h4> */}

          <h4>
            {" "}
            <button className="aftSignupBtn1" onClick={verifyAgain}>
              Send verification E-mail again
            </button>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default AfterSignup;
