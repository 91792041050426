import React from "react";
import "./Footer.css";
// import InstagramIcon from "@mui/icons-material/Instagram";
import {
  FaFacebook,
  FaInstagram,
  // FaYoutube,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";

function Footer() {
  return (
    <div className="footerbox">
      <div className="footer">
        <a href="https://www.facebook.com/marchclosing" target="">
          <FaFacebook className="icon" />
        </a>
        <a href="https://www.instagram.com/marchclosing" target="">
          <FaInstagram className="icon" />
        </a>

        <a href="https://www.linkedin.com/company/marchclosing" target="">
          <FaLinkedin className="icon" />
        </a>
        <a href="https://twitter.com/MarchClosing" target="">
          <FaTwitter className="icon" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
