import React from "react";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

const UserQueries = ({ user }) => {
  return (
    <div>
      <h2>Queries for {user.fname}</h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Sr. No</th>
            <th>Query ID</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {user.requests.map((request, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{request}</td>
              <td>
                <Button variant="info">
                  <Link to={`/adminpanel/${request}`} className="text-white">
                    View Details
                  </Link>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default UserQueries;
