// ErrorPage.js

import React from "react";

const ErrorPage = () => {
  return (
    <div>
      <h2>Page Not Found</h2>
      <p>Oops, something went wrong! The requested page could not be found.</p>
    </div>
  );
};

export default ErrorPage;
