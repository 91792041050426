import React, { useState } from "react";
import AdminComponent from "./AdminComponent";
import ErrorPage from "./ErrorPage";

const AdminPanel = () => {
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true);
  };

  return (
    <div>
      {hasError ? <ErrorPage /> : <AdminComponent onError={handleError} />}
    </div>
  );
};

export default AdminPanel;
