import React from "react";
import { useState, useRef } from "react";
// import { Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import ReCAPTCHA from "react-google-recaptcha";

const ForgotPass = () => {
  const [username, setUsername] = useState("");
  let [emailerr, setEmailerr] = useState("");
  let reRef = useRef(null);

  const test = async () => {
    const ctoken = await reRef.current.executeAsync();
    const data = { username, ctoken };
    const res = await fetch("https://marchclosing/api/oauth/v1/pass/forgot", {
      method: "POST",
      // mode: "no-cors",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const data2 = await res.json();
    if (data2.error) {
      setEmailerr(data2.error);
    }
  };

  let button;
  if (username === "") {
    button = (
      <div className="btnnn">
        <button className="submitme2">SUBMIT</button>
      </div>
    );
  } else {
    button = (
      <div className="btnnn">
        <button className="submitme" type="submit" onClick={test}>
          SUBMIT
        </button>
      </div>
    );
  }
  return (
    <>
      <ReCAPTCHA
        sitekey="6LcizEclAAAAACbd6POvWUG2H1nRuUxPXtQOfvBp"
        size="invisible"
        ref={reRef}
      ></ReCAPTCHA>
      <div className="instructionPass">
        <p>
          Having trouble loggin in ?{" "}
          <ul>
            <li>Username are in the form of an email address.</li>
            <li>Password are case sensitive.</li>
            <li>
              To reset your password ,enter your username registered with March
              Closing
            </li>
          </ul>
        </p>
      </div>
      <div className="email box">
        <Form className="formcss">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <label htmlFor="emails">EMAIL ADDRESS</label>
            <br></br>
            <input className="FormInput"
              type="email"
              required
              value={username}
              placeholder="Your account email"
              id="emails"
              onChange={(e) => {
                setUsername(e.target.value);
                setEmailerr("");
              }}
            />
          </Form.Group>
        </Form>
        <div className="emailer error">{emailerr}</div>
      </div>
      {button}
      <div className="footerlogin">
        <a href={"?mode=login"} style={{ textDecoration: "none" }}>
          <span>Back To Log In</span>
        </a>
      </div>
      <div className="footerlogin">
        Don't have an account?{" "}
        <a href={"?mode=signup"} style={{ textDecoration: "none" }}>
          <span>Sign Up</span>
        </a>
      </div>
    </>
  );
};

export default ForgotPass;
