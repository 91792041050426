import React, { useState } from "react";

function Form() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [Ticket, setTicket] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Create an object with the form data
    const formData = {
      name: name,
      phone: phone,
      email: email,
      description: description,
    };
    console.log({ formData });

    try {
      // Send the form data to your backend API using fetch or axios
      const response = await fetch(
        "https://marchclosing.com/api/oauth/v1/contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // Handle the successful submission
        const data = await response.json();
        const { Ticket } = data; // Assuming the API response contains the ticket ID
        setTicket(Ticket);
      } else {
        // Handle the error
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (Ticket) {
    return (
      <div className="ticket-info">
        <h1>
          {" "}
          Hey , thanks for sending us query , our team will be in touch with you
          soon .
        </h1>
        <p>Your ticket ID: {Ticket}</p>
      </div>
    );
  }

  return (
    <div className="contact-form">
      <form onSubmit={handleSubmit} className="contact-inputs">
        <label className="label1">Name</label>
        <input
          className="inputinfo"
          type="text"
          name="username"
          autoComplete="off"
          required
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <label className="label1">Phone</label>
        <input
          className="inputinfo"
          type="text"
          name="number"
          autoComplete="off"
          required
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
        />
        <label className="label1">Email</label>
        <input
          className="inputinfo"
          type="email"
          name="Email"
          autoComplete="off"
          required
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <label className="label1">Description</label>
        <input
          className="textarea"
          name="message"
          autoComplete="off"
          required
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
        <input className="submitrequest" type="submit" value="Submit" />
      </form>
    </div>
  );
}

export default Form;
