import React from "react";
const data_products = [
  {
    id: "p1",
    heading: "Tax Consultants",
    desc: "ITR & GST filing, business & legal compliance",
    img: require("../images/Consultant.jpg"),
  },
  {
    id: "p2",
    heading: "ITR e-filing",
    desc: "File tax returns in under 3 minutes",
    img: require("../images/ITRFilling.jpg"),
  },
  {
    id: "p3",
    heading: "E-invoicing",
    desc: "Bulk invoice in Tally or any ERP",
    img: require("../images/invoice.jpg"),
  },
  {
    id: "p4",
    heading: "GST software",
    desc: "G1-G9 filings made 3x faster",
    img: require("../images/GSTSoftware.jpg"),
  },
];

const Products = () => {
  const cards = data_products.map((card) => (
    <div class="boxcards">
      <img src={card.img} alt="Logo" width={30} />
      <h3>{card.heading}</h3>
      <p>{card.desc}</p>
    </div>
  ));
  return (
    <div className="newcards">
      <h2>Popular Products</h2>
      <div className="custom">{cards}</div>
    </div>
  );
};
export default Products;
