import React, { useState } from "react";
import { Link } from "react-router-dom";
import Flipcard from "./BannerFlipcard";

function Bannerhome() {
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 840px)").matches
  );
  window.onresize = function (event) {
    setMatches(window.matchMedia("(max-width: 840px)").matches);
  };
  let brr = "";
  if (matches === true) {
    brr = <br />;
  }
  return (
    <div className="innerHome">
      <div className="banner-home">
        <div className="banner-content">
          <h1 className="main-head">
            <span style={{ color: "#595959" }}>Let us worry about your</span>
            {brr}

            <span style={{ color: "#dd3333" }}> Tax </span>

            <span style={{ color: "#595959" }}> & </span>

            <span style={{ color: "#dd3333" }}>Savings</span>
          </h1>
          <h2 className="second-head">
            <span style={{ color: "#595959" }}>
              Tax & accounting solution faster and user friendly way
            </span>
          </h2>
          <div className="bannerbuttons">
            <Link to={"/contactus"} style={{ textDecoration: "none" }}>
              <button className="banner-button1 btnn"> Get Started </button>
            </Link>
            <Link to={"/tax"} style={{ textDecoration: "none" }}>
              {" "}
              <button className="banner-button2 btnn"> Calculate Tax </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="testimonials">
        <Flipcard />
      </div>
    </div>
  );
}

export default Bannerhome;
