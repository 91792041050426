import React, { useState, useEffect } from "react";
import "./admincomponent.css";
import Button from "react-bootstrap/Button";
import UserQueries from "./Adminuserqueries";

const AdminComponent = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const authToken = localStorage.getItem("accessToken");

  useEffect(() => {
    fetch("https://marchclosing.com/api/admin/users", {
      headers: {
        Authorization: `Bearer ${authToken}`,
        // Add other headers if needed
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setUsers(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const handleViewClick = (user) => {
    setSelectedUser(user);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Oops, something went wrong!</p>;
  }

  return (
    <div className="mainadmin">
      <h1> Welcome admin </h1>
      <h2>Registered users</h2>
      <table className="user-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Queries</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>{user.fname}</td>
              <td>{user.username}</td>
              <td>{user.requests.length}</td>
              <td>
                <Button onClick={() => handleViewClick(user)}>View</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedUser && <UserQueries user={selectedUser} />}
    </div>
  );
};

export default AdminComponent;
