import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import {Options,YesOptions,NoOptions,IncomeOptions,HRAOptions, GSTOptions} from "./options";
import BotAvatar from "./botAvatar";

const config = {
  initialMessages: [createChatBotMessage(`Hi! Welcome to March Closing !`)],
  botName:"March Closing",
  customComponents:{
    botAvatar:(props)=><BotAvatar{...props}/>  },
  widgets: [
    {
      widgetName: 'options',
      widgetFunc: (props) => <Options {...props} />,
    },
    {
      widgetName: 'yesOptions',
      widgetFunc: (props) => <YesOptions {...props} />,
    },
    {
      widgetName: 'noOptions',
      widgetFunc: (props) => <NoOptions {...props} />,
    },
    {
      widgetName: 'IncomeOptions',
      widgetFunc: (props) => <IncomeOptions {...props} />,
    },
    {
      widgetName: 'HRAOptions',
      widgetFunc: (props) => <HRAOptions {...props} />,
    },
    {
      widgetName: 'GSTOptions',
      widgetFunc: (props) => <GSTOptions {...props} />,
    },
  ],
}

export default config;