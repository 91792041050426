import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./comment.css";

function CommentSection(props) {
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);

  const fetchComments = useCallback(() => {
    // Fetch previous comments for the specific ticket ID using the API
    fetch(
      `https://marchclosing.com/api/oauth/v1/request/${props.ticket}/comments`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch comments");
        }
        return response.json();
      })
      .then((data) => {
        // Assuming 'data' is an array of comment objects with 'body' property
        const commentsArray = data.map((commentObj) => commentObj);
        setComments(commentsArray);
        console.log(commentsArray); // Verify the comments array
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
      });
  }, [props.ticket]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmitComment = () => {
    const newComment = {
      content: comment,
      time: new Date(),
      // showReplies: false,
    };

    // Save the new comment for the specific ticket ID using the API
    // Replace <API_ENDPOINT> with the actual API endpoint URL
    axios
      .post(
        `https://marchclosing.com/api/oauth/v1/request/${props.ticket}/comment`,
        newComment
      )
      .then((response) => {
        // Assuming response.data contains the new comment object
        // const updatedComments = [...comments, response.data];
        // setComments(updatedComments);
        console.log(response.data.result);
        setComment(""); // Clear the comment input after successful submission
        fetchComments();
      })
      .catch((error) => {
        console.error("Error saving comment:", error);
        // Handle the error here, e.g., display an error message to the user.
      });
  };

  // const handleToggleReplies = (index) => {
  //   const updatedComments = [...comments];
  //   updatedComments[index].showReplies = !updatedComments[index].showReplies;
  //   setComments(updatedComments);
  // };

  // const formatTimeAgo = (time) => {
  //   const currentTime = new Date();
  //   const commentTime = new Date(time);
  //   const timeDifference = currentTime - commentTime;
  //   const minutes = Math.floor(timeDifference / (1000 * 60));
  //   const hours = Math.floor(minutes / 60);

  //   if (minutes < 1) {
  //     return "Just now";
  //   } else if (hours > 0) {
  //     return `${hours} hour(s) ago`;
  //   } else {
  //     return `${minutes} minute(s) ago`;
  //   }
  // };
  function formatTimeAgo(createdAt) {
    const currentTime = new Date();
    const commentTime = new Date(createdAt);
    const timeDifference = currentTime - commentTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (seconds < 60) {
      return `${seconds} second${seconds === 1 ? "" : "s"} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else {
      const days = Math.floor(hours / 24);
      return `${days} day${days === 1 ? "" : "s"} ago`;
    }
  }

  return (
    <div className="comment-section">
      <h4>Additional Comments:</h4>
      <div className="commentsection">
        <textarea value={comment} onChange={handleCommentChange} />
      </div>
      <button
        className="commentbutton"
        type="button"
        onClick={handleSubmitComment}
      >
        Post
      </button>

      <div>
        <h4>Comments and Replies:</h4>
        {comments.map((comment, index) => (
          <div
            className={`replies ${
              comment.isAdminComment ? "admin-comment" : ""
            }`}
            key={index}
          >
            <h6>
              <span>{comment.isAdminComment ? "Admin -" : "You -"}:</span>{" "}
              {comment.body}
            </h6>

            <p>{formatTimeAgo(comment.createdAt)}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CommentSection;
