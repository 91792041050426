import React, { useState } from "react";

import "./Header.css";

import { GiHamburgerMenu } from "react-icons/gi";
import marchclosing from "../images/marchclosing.png";
import { Link, NavLink, useNavigate } from "react-router-dom";

const Header = () => {
  let checkToken = async () => {
    let authToken = localStorage.getItem("accessToken");
    const res = await fetch(
      "https://marchclosing.com/api/oauth/v1/checktoken",
      {
        method: "GET",
        // mode: "no-cors",
        headers: {
          Authorization: `bearer ${authToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const data2 = await res.json();
    if (data2.error) {
      handleLogout();
    }
  };
  let UserId = localStorage.getItem("UserID");
  const userLink = `/user/${UserId}`;
  const navigator = useNavigate();
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [showToolsDropdown, setShowToolsDropdown] = useState(false);

  const toggleToolsDropdown = () => {
    if (showToolsDropdown === true) {
      setShowToolsDropdown(false);
    } else {
      setShowToolsDropdown(true);
    }
  };
  function handleLogout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.reload(true);
    navigator("/");
  }
  let authButton;
  let profileButton;
  let authToken = localStorage.getItem("accessToken");
  // let authToken=document.cookie.split("; ").find((row) => row.startsWith("accessToken="))?.split("=")[1];
  if (authToken !== null && authToken !== "") {
    checkToken();
    profileButton = (
      <li>
        <NavLink
          className="unactive"
          to={userLink}
          onClick={() => setShowMediaIcons(false)}
        >
          MY PROFILE
        </NavLink>
      </li>
    );
    authButton = (
      <div class="button-div">
        <NavLink className="un" to="/" onClick={() => setShowMediaIcons(false)}>
          <button class="logout-button" onClick={handleLogout}>
            LOGOUT
          </button>
        </NavLink>
      </div>
    );
  } else {
    authButton = (
      <div class="button-div">
        <a
          className="un"
          href="/auth?mode=signup"
          onClick={() => setShowMediaIcons(false)}
        >
          <button class="signup-button">SIGN UP</button>
        </a>
        <a
          className="un"
          href="/auth?mode=login"
          onClick={() => setShowMediaIcons(false)}
        >
          <button class="login-button">LOG IN</button>
        </a>
      </div>
    );
  }

  return (
    <>
      <div className="outercontainer">
        <div className="widthcontainer">
          <nav className="main-nav2">
            {/* 1st logo part  */}

            <div className="main-nav">
              <div className="logo">
                <h2>
                  <NavLink to="/">
                    {" "}
                    <img src={marchclosing} alt="logo" width={250} />
                  </NavLink>
                </h2>
              </div>

              {/* 2nd menu part  */}
              <div
                className={
                  showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
                }
              >
                <ul>
                  <li>
                    <NavLink
                      className="unactive"
                      to="/"
                      onClick={() => setShowMediaIcons(false)}
                    >
                      HOME
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="unactive"
                      to="/tax"
                      onClick={() => setShowMediaIcons(false)}
                    >
                      TAX
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      className="unactive"
                      to="/gst"
                      onClick={() => setShowMediaIcons(false)}
                    >
                      GST
                    </NavLink>
                  </li>
                  <li className="dropdown">
                    <Link
                      className="unactive"
                      to="/tools"
                      onClick={toggleToolsDropdown}
                    >
                      TOOLS
                    </Link>
                    {/* <div
                      className={
                        showToolsDropdown
                          ? "dropdown-content show"
                          : "dropdown-content"
                      }
                    >
                      <NavLink
                        to="/tax"
                        onClick={() => setShowMediaIcons(false)}
                      >
                        TAX
                      </NavLink>
                      <NavLink
                        to="/rentslip"
                        onClick={() => setShowMediaIcons(false)}
                      >
                        RENT SLIP
                      </NavLink>
                      <NavLink
                        to="/hracalculator"
                        onClick={() => setShowMediaIcons(false)}
                      >
                        HRA CALCULATOR
                      </NavLink>
                      <NavLink
                        to="/gstcalculator"
                        onClick={() => setShowMediaIcons(false)}
                      >
                        GST CALCULATOR
                      </NavLink>
                    </div> */}
                  </li>
                  <li>
                    <NavLink
                      className="unactive"
                      to="/contactus"
                      onClick={() => setShowMediaIcons(false)}
                    >
                      CONTACT US
                    </NavLink>
                  </li>

                  {profileButton}

                  <li>{authButton}</li>

                  {/* <div className="button-div">
                    {" "}
                    <button className="nav-button ">
                      <NavLink to="/signup">Sign up</NavLink>
                    </button>
                    <button className="nav-button">
                      {" "}
                      <NavLink to="/login">Log in</NavLink>
                    </button>
                  </div> */}
                </ul>
              </div>

              <div className="hamburger">
                {/* hamburget menu start  */}
                <div
                  className="hamburger-menu"
                  onClick={() => setShowMediaIcons(!showMediaIcons)}
                >
                  <GiHamburgerMenu />
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
