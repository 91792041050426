// AdminQueryDetails.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Container, Col, Row, Button } from "react-bootstrap";
import AdminComments from "./AdminComments";
import Table from "react-bootstrap/Table";
import "./adminquerydetails.css";
// import Banner from "./Banner";
// import CommentSection from "./CommentSection";

const AdminQueryDetails = () => {
  const { request } = useParams();
  // Access the dynamic parameter from the URL

  console.log("Request:", request);

  const handleDocumentView = (base64File) => {
    const html = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Image Viewer</title>
        </head>
        <body>
          <embed src="${base64File}" alt="Image"style=" width: 100%; height: 100vh;">
        </body>
      </html>
    `;
    const newTab = window.open();
    newTab.document.write(html);
    newTab.document.close();
  };
  const [queryDetails, setQueryDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  // const [comments, setComments] = useState([]);
  // const [newComment, setNewComment] = useState("");
  const authToken = localStorage.getItem("accessToken");
  useEffect(() => {
    // Fetch query details for admin
    axios
      .get(`https://marchclosing.com/api/admin/queries/${request}/details`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        setQueryDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });

    // Fetch uploaded documents for the ticket
    axios
      .get(`https://marchclosing.com/api/admin/queries/${request}/documents`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((documentsResponse) => {
        const documents = documentsResponse.data;
        setUploadedDocuments(documents);
        // console.log(documents);
      })
      .catch((error) => {
        console.error("Error fetching uploaded documents:", error);
      });

    // Fetch comments for the ticket
    // axios
    //   .get(`http://localhost:4000/admin/queries/${request}/comments`, {
    //     headers: {
    //       Authorization: `Bearer ${authToken}`,
    //     },
    //   })
    //   .then((commentsResponse) => {
    //     setComments(commentsResponse.data);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching comments:", error);
    //   });
  }, [request]);

  const handleResolveClick = () => {
    // Check if the ticket status is "pending" before making the API call
    if (queryDetails && queryDetails.currentStatus === "Pending") {
      axios
        .put(
          `https://marchclosing.com/api/admin/queries/${request}/resolve`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((response) => {
          // Handle successful resolution
          console.log("Ticket resolved successfully:", response.data);
          // You may want to update the UI or fetch the updated ticket details
        })

        .catch((error) => {
          console.error("Error resolving ticket:", error);
          // Handle error, e.g., display an error message
        });
      window.location.reload();
    } else {
      // Handle the case where the ticket is not in "pending" status
      console.log("Ticket is not in pending status. Cannot resolve.");
      // You may want to display a message to the user
    }
  };

  // const handleCommentSubmit = (event) => {
  //   event.preventDefault();
  //   // Check if the new comment is not empty before making the API call
  //   if (newComment.trim() !== "") {
  //     axios
  //       .post(
  //         `http://localhost:4000/admin/queries/${request}/comments`,
  //         {
  //           content: newComment,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${authToken}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         // Handle successful comment submission
  //         console.log("Comment submitted successfully:", response.data);
  //         // Update the comments state
  //         setComments([...comments, response.data]);
  //         // Clear the newComment state
  //         setNewComment("");
  //       })
  //       .catch((error) => {
  //         console.error("Error submitting comment:", error);
  //         // Handle error, e.g., display an error message
  //       });
  //   }
  // };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="adminquerydetails">
      <h1>Query Details</h1>

      <Table striped bordered hover>
        <thead>
          {/* <tr>
            <th>#</th>
            <th>Field</th>
            <th>Value</th>
          </tr> */}
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Title</td>
            <td>{queryDetails.title}</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Description</td>
            <td>{queryDetails.description}</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Status</td>
            <td
              style={{
                color:
                  queryDetails.currentStatus === "Resolved"
                    ? "green"
                    : "#dd3333",
              }}
            >
              {queryDetails.currentStatus}
            </td>
          </tr>

          <tr>
            <td>4</td>
            <td>Created Date</td>
            <td>{queryDetails.createdDate}</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Ticket ID</td>
            <td>{queryDetails.ticket}</td>
          </tr>
        </tbody>
      </Table>
      {queryDetails && queryDetails.currentStatus === "Pending" && (
        <div className="resolvebutton">
          {" "}
          <button onClick={handleResolveClick}>Resolve this ticket</button>
        </div>
      )}

      <div className="doclist">
        <h2> Documents</h2>{" "}
        {uploadedDocuments.length > 0 ? (
          <table className="document-table">
            <thead>
              <tr>
                <th>Serial Number</th>
                <th>Date</th>
                <th>Name</th>
                <th>View</th>
                {/* <th> Remove</th> */}
              </tr>
            </thead>
            <tbody>
              {uploadedDocuments.map((document, index) => (
                <tr key={document._id}>
                  <td>{index + 1}</td>
                  {/* <td>
                          <a
                            href={document.base64}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="document-link"
                          >
                            View Document
                          </a>
                          <td>
                        </td> */}
                  <td>{document.createdAt}</td>
                  <td>{document.base64.split(";")[0].split(":")[1]}</td>
                  <td>
                    <Button
                      onClick={() => handleDocumentView(document.base64)}
                      className="open-button"
                    >
                      View Document
                    </Button>
                  </td>
                  {/* <td></td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No documents uploaded yet.</p>
        )}
      </div>
      <div className="comments">
        {" "}
        <h2>Comments</h2>
      </div>

      <div>
        {" "}
        <AdminComments ticket={request} />
      </div>
    </div>
  );
};

export default AdminQueryDetails;
