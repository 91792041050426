import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Carousel } from "react-bootstrap";
import "./guide.css";
const data_articles = [
  {
    id: "a1",
    link: "/article1",
    title: "New Tax Regime",
    description: "All you need to know about the new tax regime.",
    img: require("../images/coins.jpg"),
  },
  {
    id: "a2",
    link: "/article2",
    title: "Future wealth plans",
    description: "Save wealth for a better family health.",
    img: require("../images/family.jpg"),
  },
  {
    id: "a3",
    link: "/article3",
    title: "E-Invoicing",
    description: "How E-Invoicing can be a game-changer.",
    img: require("../images/gandhi.jpg"),
  },
  {
    id: "a4",
    link: "/article4",
    title: "GST in 2023",
    description: "Get all the information about GST.",
    img: require("../images/card.jpg"),
  },
  {
    id: "a5",
    link: "/article5",
    title: "Budget 2023-2024",
    description: "Understand the positives and negatives.",
    img: require("../images/bitcoin.jpg"),
  },
  {
    id: "a6",
    link: "/article6",
    title: "Use of Tech in Accounting",
    description: "Get insights about the best tech.",
    img: require("../images/tech.jpg"),
  },
];

const Guide = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const chunkSize = 3; // Number of cards to display at a time
  const chunkedArticles = data_articles.reduce((acc, _, index) => {
    if (index % chunkSize === 0) {
      acc.push(data_articles.slice(index, index + chunkSize));
    }
    return acc;
  }, []);

  const carouselItems = chunkedArticles.map((chunk, chunkIndex) => (
    <Carousel.Item key={chunkIndex}>
      <div className="d-flex justify-content-center">
        {chunk.map((article) => (
          <Link
            to={article.link}
            key={article.id}
            style={{ textDecoration: "none" }}
          >
            <div
              className="guide-card"
              style={{ backgroundImage: `url(${article.img})` }}
            >
              {/* <img src={article.img} alt={article.title} /> */}
              <div className="guide-card-content">
                <h3>{article.title}</h3>
                <p>{article.description}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Carousel.Item>
  ));

  return (
    <div className="slider">
      <Container>
        <div className="carousel-container">
          <Carousel
            activeIndex={activeIndex}
            onSelect={(index) => setActiveIndex(index)}
            prevLabel=""
            nextLabel=""
            prevIcon={<span>&#10094;</span>}
            nextIcon={<span>&#10095;</span>}
            prevClassName="carousel-prev-button"
            nextClassName="carousel-next-button"
          >
            {carouselItems}
          </Carousel>
        </div>
      </Container>
    </div>
  );
};

export default Guide;
