import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import { Container, Form, Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import "./user.css";
import userpic from "../images/userprofile.png";
import { useParams, useNavigate } from "react-router-dom";

function Usertest() {
  const navigate = useNavigate();
  const params = useParams();
  const UserID = params.id;
  let Token = localStorage.getItem("accessToken");
  const [user, setUser] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files[0].size > 1048576) {
      alert("File is too big!");
      setSelectedImage(selectedImage2);
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        console.log(reader.result);
        setSelectedImage(reader.result);
      };
    }
    // const file = e.target.files[0];
    // setSelectedImage(URL.createObjectURL(file));
  };
  // const handleImageCancel = () => {
  //   setSelectedImage(selectedImage2);
  // };
  const handleImageUpload = async () => {
    if (selectedImage) {
      const authToken = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("UserID");
      await fetch("https://marchclosing.com/api/oauth/v1/userProfile/picture", {
        method: "POST",
        // mode: "no-cors",
        body: JSON.stringify({ base64: selectedImage }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${authToken} ${userId}`,
          Accept: "application/json",
        },
      });
      alert("Image saved successfully!");
    }
    const authToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("UserID");
    const res2 = await fetch(
      "https://marchclosing.com/api/oauth/v1/userProfile/picture",
      {
        method: "GET",
        // mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${authToken} ${userId}`,
          Accept: "application/json",
        },
      }
    );
    const data3 = await res2.json();
    // console.log(data3)
    if (data3.base64) {
      console.log(data3.profilePic);
      setSelectedImage(data3.base64);
      setSelectedImage2(data3.base64);
    }
  };
  const handleEdit = () => {
    setIsEditing(true);
    setEditMode(true);
  };
  useEffect(() => {
    async function handleLoad() {
      const data = { UserID };
      var bearer = "Bearer " + Token;
      const res = await fetch(
        "https://marchclosing.com/api/oauth/v1/userinfo",
        {
          method: "POST",
          // mode: "no-cors",
          body: JSON.stringify(data),
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const data2 = await res.json();
      if (data2.error) {
        navigate("/");
      }
      setUser(data2.UserDetail);
      console.log(user.fname);
      console.log(data2);
      // setFormData(data2);

      const authToken = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("UserID");
      const res2 = await fetch(
        "https://marchclosing.com/api/oauth/v1/userProfile/picture",
        {
          method: "GET",
          // mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${authToken} ${userId}`,
            Accept: "application/json",
          },
        }
      );
      console.log(res2);
      const data3 = await res2.json();
      console.log(data3);
      if (data3.base64) {
        console.log(data3.profilePic);
        setSelectedImage(data3.base64);
        setSelectedImage2(data3.base64);
      }
    }
    handleLoad();
  }, [UserID, Token, navigate, user.fname]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    // const data={UserID}
    var bearer = "Bearer " + Token;
    // console.log(formData);
    fetch("https://marchclosing.com/api/oauth/v1/userSetdata", {
      method: "POST",
      // mode: "no-cors",
      body: JSON.stringify(formData),
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUser(data);
        setFormData(data);
        setEditMode(false);
        navigate(`/user/${UserID}`);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="container">
      <h1>
        Hey,
        <span style={{ color: "#dd3333" }}> Welcome!! </span>
      </h1>

      <Container>
        <Row>
          <Col xs={12} lg={5} md={6}>
            <div className="imagesection">
              <div className="userimage">
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="Profile"
                    width={250}
                    height={250}
                  />
                ) : (
                  <img src={userpic} alt="user" width={250} />
                )}
                {editMode ? (
                  <div className="profilebtn">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <button class="upload-86" onClick={handleImageUpload}>
                        Save
                      </button>
                      <button
                        class="button-86"
                        onClick={() => {
                          setIsEditing(false);
                          setEditMode(false);
                          setSelectedImage(selectedImage2);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <h3> {formData?.fname || user.fname}</h3>
            </div>
          </Col>
          <Col xs={12} lg={7} md={6}>
            <div className="userinfo">
              <Form>
                <Row>
                  <Col>
                    <Form.Group controlId="formBasicUsername">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        name="fname"
                        readOnly={!isEditing}
                        disabled={!isEditing}
                        value={formData?.fname || user.fname}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    ​
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        name="username"
                        readOnly={!isEditing}
                        disabled="disabled"
                        value={formData?.username || user.username}
                        onChange={handleInputChange}
                        // disabled="disabled"
                      />
                    </Form.Group>
                    ​
                    <Form.Group controlId="formBasicPhone">
                      <Form.Label>Phone number</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        readOnly={!isEditing}
                        disabled={!isEditing}
                        value={formData?.phone || user.phone}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formBasicAddress">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        readOnly={!isEditing}
                        disabled={!isEditing}
                        value={formData?.address || user.address}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    ​
                    <Form.Group controlId="formBasicPin">
                      <Form.Label>PIN</Form.Label>
                      <Form.Control
                        type="text"
                        name="pin"
                        readOnly={!isEditing}
                        disabled={!isEditing}
                        value={formData?.pin || user.pin}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    ​
                    <Form.Group controlId="formBasicPan">
                      <Form.Label>PAN</Form.Label>
                      <Form.Control
                        type="text"
                        name="pan"
                        readOnly={!isEditing}
                        disabled={!isEditing}
                        value={formData?.pan || user.pan}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                ​
                {editMode ? (
                  <div className="afteredit">
                    <Button
                      className="editbutton"
                      variant="primary"
                      onClick={handleUpdate}
                    >
                      Update
                    </Button>{" "}
                    <Button
                      className="useredit"
                      variant="danger"
                      onClick={() => {
                        setIsEditing(false);
                        setEditMode(false);
                        setSelectedImage(selectedImage2);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <Button
                    className="useredit"
                    variant="primary"
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                )}
              </Form>
            </div>
          </Col>
        </Row>
        ​
        {/* <Row>
          <h3> Saved documents</h3>
        </Row> */}
      </Container>
    </div>
  );
}

export default Usertest;
