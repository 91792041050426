import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
// import exchangeRupee from "../images/exchangeRupee.png";
import Banner from "./Banner";
import "./tools.css";
const data_flipcards = [
  {
    id: "p1",
    link: "/tax",
    heading: "Tax Calculator",
    desc: "Fastest income tax calculator that helps you calculate your tax according to different regimes & financial years. ",
    btn: "Calculate your tax",
    img: require("../images/calculator.png"),
  },
  {
    id: "p2",
    link: "/hracalculator",
    heading: "HRA Calculator",
    desc: "Calculate how much amount is your HRA exempt , just enter your few basic details and you're good to go.",
    btn: "Check Out",
    img: require("../images/house.png"),
  },
  {
    id: "p3",
    link: "/gstcalculator",
    heading: "GST Calculator",
    desc: "Calculate GST on various commodities and services . Enter amount and select the percentage by youself.",
    btn: "Find out",
    img: require("../images/tax.png"),
  },
  {
    id: "p4",
    link: "/rentslip",
    heading: "Rent Slip",
    desc: "Generate both monthly and quearterly rent slips within seconds and get them printed with just a click. ",
    btn: "Start",
    img: require("../images/rent.png"),
  },
];

const Flipcard = () => {
  const cards = data_flipcards.map((card) => (
    <Col key={card.id} md={6} lg={3}>
      <Link to={card.link} style={{ textDecoration: "none" }}>
        <div className="flip-card1">
          <div className="tooldata">
            <h1>{card.heading}</h1>
            <h3>{card.desc}</h3>
            {/* <p>{card.btn}</p> */}
          </div>

          <div className="toolicons">
            <img alt="icon" src={card.img} width={80} />
          </div>
        </div>
      </Link>
    </Col>
  ));

  return (
    <div>
      <Banner
        h1Content=" Check our fast & "
        h1Span={<span style={{ color: "#dd3333" }}> accurate </span>}
        h1Content2=" financial help  "
        h1Span2={<span style={{ color: "#dd3333" }}> Tools</span>}
        pageDescription="Make your financial life easy with our fastest tools "
      />

      <Container>
        <Row>
          <Col>
            <div className="d-flex flex-wrap">{cards}</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Flipcard;
