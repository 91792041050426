import React, { useState, useEffect } from "react";
import axios from "axios";
import "./comment.css";

function CommentSection(props) {
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);

  useEffect(() => {
    fetchComments();
  });

  const fetchComments = () => {
    // Fetch previous comments for the specific ticket ID using the API
    fetch(`http://localhost:4000/oauth/v1/request/${props.ticket}/comments`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch comments");
        }
        return response.json();
      })
      .then((data) => {
        // Assuming 'data' is an array of comment objects with 'body' property
        const commentsArray = data.map((commentObj) => commentObj.body);
        setComments(commentsArray);
        console.log(commentsArray); // Verify the comments array
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
      });
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmitComment = () => {
    const newComment = {
      content: comment,
      // time: new Date(),
      // showReplies: false,
    };

    // Save the new comment for the specific ticket ID using the API
    // Replace <API_ENDPOINT> with the actual API endpoint URL
    axios
      .post(
        `http://localhost:4000/oauth/v1/request/${props.ticket}/comment`,
        newComment
      )
      .then((response) => {
        // Assuming response.data contains the new comment object
        const updatedComments = [...comments, response.data];
        setComments(updatedComments);
        setComment(""); // Clear the comment input after successful submission
      })
      .catch((error) => {
        console.error("Error saving comment:", error);
        // Handle the error here, e.g., display an error message to the user.
      });
  };

  const handleToggleReplies = (index) => {
    const updatedComments = [...comments];
    updatedComments[index].showReplies = !updatedComments[index].showReplies;
    setComments(updatedComments);
  };

  const formatTimeAgo = (time) => {
    const currentTime = new Date();
    const commentTime = new Date(time);
    const timeDifference = currentTime - commentTime;
    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(minutes / 60);

    if (minutes < 1) {
      return "Just now";
    } else if (hours > 0) {
      return `${hours} hour(s) ago`;
    } else {
      return `${minutes} minute(s) ago`;
    }
  };

  return (
    <div className="comment-section">
      <h4>Additional Comments:</h4>
      <div className="commentsection">
        <textarea value={comment} onChange={handleCommentChange} />
      </div>
      <button
        className="commentbutton"
        type="button"
        onClick={handleSubmitComment}
      >
        Post
      </button>

      <div>
        <h4>Comments and Replies:</h4>
        {comments.map((comment, index) => (
          <div className="replies" key={index}>
            <p>{comment}</p>
            {/* Toggle button for replies */}
            <button
              className="submit-button"
              onClick={() => handleToggleReplies(index)}
            >
              {comment.showReplies ? "Hide Replies" : "Show Replies"}
            </button>

            {/* No need for the replies loop since 'comment' is a string */}
            {comment.showReplies && comment.replies && (
              <div className="repliesmain" style={{ marginLeft: "20px" }}>
                {/* Loop through replies (if needed) */}
                {comment.replies.map((reply, replyIndex) => (
                  <div className="replies" key={replyIndex}>
                    <h5 className="replies" style={{ fontSize: "14px" }}>
                      {reply.content}
                    </h5>
                    <h5 className="replies" style={{ fontSize: "14px" }}>
                      {formatTimeAgo(reply.time)}
                    </h5>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CommentSection;
