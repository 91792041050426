import React from "react";
import "./gst.css";
// import Button from "react-bootstrap/Button";
import Form from "./Form";
import Banner from "./Banner";
import { Link } from "react-router-dom";

function Gst() {
  return (
    <div className="maingst">
      <div className="contactus">
        <Banner
          h1Content="GST"
          h1Span={<span style={{ color: "#dd3333" }}></span>}
          h1Content2="Now!"
          h1Span2={<span style={{ color: "#dd3333" }}>Simplified.</span>}
          pageDescription="More goods, more taxes. No worries! We've got your back ."
        />
          <div className="outerRaiserequest">
        <div className="insidecontainer">
          <div className="raiserequest">
            <h4>GST Services</h4>
            <div className="details">
              <ul>
                <li>
                  Get your Business compliant under GST and file periodic
                  returns {<br />} under
                  <span> March Closing's </span>experts guidance.
                </li>
                <li>GST Returns (GSTR-4) for 1 quarter for ONE GSTIN</li>
                <li>Filing for B2B and B2C invoices</li>
              </ul>
            </div>
          </div>
          <Form />
          </div>
          <div class="cardGST">
              <div class="content">
                <div class="title">Goods and Services Tax</div>
                <div class="price">Calculate GST</div>
                <div class="description">Calculate Your GST Amount Online,GST calculator enables you to find pre-GST and post-GST amounts based on specific GST rates.</div>
              </div>
              <Link to='/gstcalculator' style={{ textDecoration: "none",width:"100%"}}>
              <button className="gstCalc-Button">
                  Calculate now
                </button>
              </Link>
                
          </div>
        </div>
      </div>
      <div className="outerGSTBlog">

      <div className="gstblog">
        <h2>What is GST?</h2>
        <p>
          <span>1. </span>The Goods and Services Tax (GST) is a value-added tax
          introduced in India on 1 July 2017.
        </p>
        <p>
          <span>2. </span> It is a comprehensive, multistage, destination-based
          tax: comprehensive because it has subsumed almost all the indirect
          taxes except a few state taxes. Multistage because it is levied at
          every point in the supply chain.
        </p>
        <p>
          <span>3. </span> Destination-based because it is levied on the
          consumption of goods and services at the place of consumption and not
          at the point of origin like previous taxes.
        </p>

        <h2>What are the Significant Aspects of GST law?</h2>
        <p>
          {" "}
          <span>1.</span>The GST is levied on the supply of goods and services
          at each stage of the supply chain, from manufacture to final sale,
          with credit for taxes paid at previous stages allowed as an input tax
          credit.
        </p>
        <p>
          {" "}
          <span>2.</span> The end consumer pays the GST but is usually
          reimbursed by the supplier.
        </p>
        <p>
          {" "}
          <span>3.</span> The GST rate is the same regardless of the value of
          the good or service.
        </p>

        <p>
          <span>4.</span> GST is a destination-based tax, meaning that GST is
          levied on the consumption of goods and services, rather than on their
          production or sale.
        </p>
        <p>
          {" "}
          <span>5.</span> The place of consumption is generally the place where
          the goods or service is supplied, but there are some exceptions.
        </p>
        <p>
          <span>6.</span> GST is a tax on the supply of goods and services, not
          on the value of the goods or services.
        </p>
        <p>
          <span>7.</span> GST is a multistage tax, levied at each stage of the
          supply chain from manufacturer to final sale.
        </p>
        <p>
          {" "}
          <span>8.</span> The GST paid at each stage is creditable against the
          GST payable at the next stage, so only the value-added is taxed.
        </p>
      </div>
      </div>
    </div>
  );
}

export default Gst;
