import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "./hra.css";
import Banner from "./Banner";
import marchclosing from "../images/marchclosing.png";
import { Link } from "react-router-dom";

function HraCalculator() {
  const [basicSalary, setBasicSalary] = useState("");
  const [daReceived, setDaReceived] = useState("");
  const [hraReceived, setHraReceived] = useState("");
  const [rentPaid, setRentPaid] = useState("");
  const [taxableHra, setTaxableHra] = useState(0);
  const [cityType, setCityType] = useState("yes");
  const [rentOverTenPercent, setRentOverTenPercent] = useState();
  const [showResult, setShowResult] = useState(false);
  const [exemptedHra, setExemptedHra] = useState(0);
  const basic = parseFloat(basicSalary);
  const fortyPercentBasic = 0.4 * basic;
  const da = daReceived ? parseFloat(daReceived) : 0;
  const fiftyPercentBasic = 0.5 * basic;
  const basicWithDa = basic + da;
  const handleCityTypeChange = (event) => {
    setCityType(event.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (basicSalary && hraReceived && rentPaid && cityType) {
      const hra = parseFloat(hraReceived);
      const rent = parseFloat(rentPaid);

      const rentOverTenPercent = Math.max(0, rent - 0.1 * basicWithDa);

      setRentOverTenPercent(rentOverTenPercent);

      console.log("rentOverTenPercent", rentOverTenPercent, basic);
      let exemptedHra = Math.min(hra, fortyPercentBasic, rentOverTenPercent);
      if (cityType === "yes") {
        exemptedHra = Math.min(hra, fiftyPercentBasic, rentOverTenPercent);
      }

      setExemptedHra(exemptedHra);
      setTaxableHra(hra - exemptedHra);
    }
    setShowResult(true);
  };
  const handleReset = () => {
    setBasicSalary("");
    setDaReceived("");
    setHraReceived("");
    setRentPaid("");
    setCityType("yes");
    setTaxableHra();
    setRentOverTenPercent();
    setShowResult(false);
  };

  return (
    <div>
      <Banner
        h1Content="We care about your savings in"
        h1Span={<span style={{ color: "#dd3333" }}> HRA.</span>}
        h1Content2=""
        h1Span2={<span style={{ color: "#dd3333" }}></span>}
        pageDescription="Check out our HRA exemption calculator"
      />
      <Container className="hra">
        <Row>
          <Col></Col>
        </Row>

        {!showResult ? (
          <Row>
            <Col xs={12} lg={6} md={6} className="mx-auto">
              <div className="hrabox">
                <h1>
                  <span>HRA Calculator</span>
                </h1>
                <h2>
                  by {""}
                  <img src={marchclosing} alt="logo" width={140} />
                </h2>
              </div>
              <Form onSubmit={handleSubmit} className="form-center">
                <Row className="mb-3">
                  {" "}
                  <Form.Group as={Col} controlId="formGridBasicsalary">
                    <Form.Label>Basic Salary</Form.Label>
                    <Form.Control
                      type="Income"
                      placeholder=""
                      value={basicSalary}
                      onChange={(e) => setBasicSalary(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridDareceived">
                    <Form.Label>DA Received</Form.Label>
                    <Form.Control
                      type="da"
                      placeholder=""
                      value={daReceived}
                      onChange={(e) => setDaReceived(e.target.value)}
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridhrareceived">
                    <Form.Label>HRA Received</Form.Label>
                    <Form.Control
                      type="hra"
                      placeholder=""
                      value={hraReceived}
                      onChange={(e) => setHraReceived(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridrentpaid">
                    <Form.Label>Rent Paid</Form.Label>
                    <Form.Control
                      type="rent"
                      placeholder=""
                      value={rentPaid}
                      onChange={(e) => setRentPaid(e.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="citytype">
                    <Form.Label>
                      Do you live in Delhi , Mumbai , Chennai or Kolkata
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="city"
                      value={cityType}
                      onChange={handleCityTypeChange}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Control>
                  </Form.Group>
                </Row>
                <div className="rentbuttondiv">
                  <Button
                    className="hrabutton"
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        ) : (
          <Col className="mx-auto" style={{ width: "450px" }}>
            <div className="hrabox">
              <h1>
                <span>HRA Calculator</span>
              </h1>
              <h2>
                by {""}
                <img src={marchclosing} alt="logo" width={140} />
              </h2>
            </div>
            <div className="hraresult">
              <h2>
                HRA chargable to tax {""}{" "}
                <span>
                  {" "}
                  {""} {taxableHra}
                </span>
              </h2>

              <h3>
                Excess of Rent paid over 10% of salary :{" "}
                <span>{rentOverTenPercent}</span>
              </h3>
              <h3>
                {" "}
                HRA Received : {""} <span>{hraReceived} </span>
              </h3>
              {basicSalary && (
                <React.Fragment>
                  {cityType === "no" ? (
                    <h3>
                      40% of Basic Salary: <span> {fortyPercentBasic}</span>
                    </h3>
                  ) : (
                    <h3>
                      50% of Basic Salary: <span> {fiftyPercentBasic}</span>
                    </h3>
                  )}

                  <h4> The least of the above three is exempt from HRA </h4>

                  <h5> Amount of exempted HRA : {exemptedHra}</h5>
                </React.Fragment>
              )}
            </div>

            <Button
              className="hraresultbutton"
              variant="primary"
              onClick={handleReset}
            >
              Calculate again
            </Button>

            <div className="hraboxresult">
              <h1>Start saving money with us</h1>
              <h2>We have hassle free ITR filing and other solutions</h2>
              <p>
                For any such queries do contact us.{" "}
                <span>
                  <Link to={"/contactus"}>Click here</Link>
                </span>{" "}
              </p>
            </div>
          </Col>
        )}
      </Container>
    </div>
  );
}

export default HraCalculator;
