import React from "react";

import Header from "./Components/Header";
import Home from "./Components/Home";

import Footer from "./Components/Footer";
import Newreg from "./Components/Newreg";
import Existing from "./Components/Existing";
import AboutUs from "./Components/AboutUs";
import Tools from "./Components/Tools";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { Switch } from "react-router";
import QueryDetails from "./Components/Querydetails";
import "./app.css";
import Tax from "./Components/Tax";
import Gst from "./Components/Gst";
import GSTCalculator from "./Components/Tools/GstCalculator";
// import Login from "./Components/Login";
// import Newsignup from "./Components/Newsignup";
// import Contact from "./Components/Contact";
import Rent from "./Components/Rent";
import Dashboard from "./Components/Dashboard";
import HraCalculator from "./Components/HraCalculator";
import AuthenticationPage, {
  action as authAction,
} from "./Components/Authentication";
import ResetPassword from "./pages/ResetPassword";
import EmailVerify from "./pages/AfterVerification";

import UserInfo from "./Components/Usertest";
import Guide from "./Components/Guide";
import Article1 from "./Components/Articles/Article1";
import Article2 from "./Components/Articles/Article2";
import Article3 from "./Components/Articles/Article3";
import Article4 from "./Components/Articles/Article4";
import Article5 from "./Components/Articles/Article5";
import Article6 from "./Components/Articles/Article6";
import Test from "./Components/Test";
import InvoiceGenerator from "./Components/InvoiceTest";
import AdminPanel from "./Components/Admin/AdminPanel";
import AdminQueryDetails from "./Components/Admin/Adminquerydetails";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        {" "}
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tax" element={<Tax />} />
          <Route path="/newreg" element={<Newreg />} />
          <Route path="/existing" element={<Existing />} />
          <Route path="/gst" element={<Gst />} />
          <Route path="/contactus" element={<AboutUs />} />
          {/* <Route path="/signup" element={<Newsignup />} /> */}
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/rentslip" element={<Rent />} />
          <Route path="/gstcalculator" element={<GSTCalculator />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/user/:Id" element={<UserInfo />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/hracalculator" element={<HraCalculator />} />
          <Route path="/verification" element={<EmailVerify />} />
          <Route path="/query/:ticket" element={<QueryDetails />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/query" element={<QueryDetails />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="/article1" element={<Article1 />} />
          <Route path="/article2" element={<Article2 />} />
          <Route path="/article3" element={<Article3 />} />
          <Route path="/article4" element={<Article4 />} />
          <Route path="/article5" element={<Article5 />} />
          <Route path="/article6" element={<Article6 />} />
          <Route path="/test" element={<Test />} />
          <Route path="/invoicing" element={<InvoiceGenerator />} />
          <Route path="/adminpanel/:request" element={<AdminQueryDetails />} />
          <Route
            path="/auth"
            element={<AuthenticationPage />}
            action={authAction}
          />
          <Route path="/resetpassword/:token" element={<ResetPassword />} />
          {/* <Route path="/user/:Id" element={<Usertest/>}/> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
