import React, { useEffect, useState } from "react";
import axios from "axios";
// import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import "./querydetails.css";
import Banner from "./Banner";
import CommentSection from "./CommentSection";
const QueryDetails = () => {
  const { ticket } = useParams();
  const [query, setQuery] = useState(null);
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState(null);
  const [priority, setPriority] = useState("normal");
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
  };
  const authToken = localStorage.getItem("accessToken");
  const userId = localStorage.getItem("UserID");
  const handleDocumentView = (base64File) => {
    const html = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Image Viewer</title>
        </head>
        <body>
          <embed src="${base64File}" alt="Image"style=" width: 100%; height: 100vh;">
        </body>
      </html>
    `;
    const newTab = window.open();
    newTab.document.write(html);
    newTab.document.close();
  };
  useEffect(() => {
    axios
      .post(
        "https://marchclosing.com/api/oauth/v1/userinfo",
        {},
        {
          headers: {
            Authorization: `bearer ${authToken} ${userId}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        const UserDetail = response.data.UserDetail;
        if (UserDetail && UserDetail.fname) {
          setFirstName(UserDetail.fname);
          setEmail(UserDetail.username);
          setPhone(UserDetail.phone);
        }
        console.log(response.data);
        console.log(UserDetail.fname);
        console.log(UserDetail.username);
      })
      .catch((error) => {
        console.error("Error fetching user info:", error);
      });
    axios
      .get(`https://marchclosing.com/api/oauth/v1/request/get/${ticket}`, {
        headers: { Authorization: `bearer ${authToken} ${userId}` },
      })
      .then((response) => {
        setQuery(response.data);
        console.log(response.data);
        console.log(response);
        // Fetch uploaded documents for the ticket
      })
      .catch((error) => {
        console.error("Error fetching query details:", error);
      });
    axios
      .get(`https://marchclosing.com/api/oauth/v1/request/document`, {
        headers: {
          Authorization: `bearer ${authToken} ${userId} ${ticket}`,
        },
      })
      .then((documentsResponse) => {
        const documents = documentsResponse.data;
        setUploadedDocuments(documents);
        console.log(documents);
      })
      .catch((error) => {
        console.error("Error fetching uploaded documents:", error);
      });
  }, [authToken, userId, ticket]);
  if (!query) {
    return <p>Loading query details...</p>;
  }
  const getStatusRemarks = () => {
    if (query.currentStatus === "Pending") {
      return "Our team is working on it";
    } else if (query.currentStatus === "Resolved") {
      return "We are happy that we could resolve your problem.";
    } else {
      return "";
    }
  };
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64File = reader.result;
        const uploadDate = new Date().toLocaleString();
        // const requestBody = {
        //   base64: base64File,
        //   reqID: ticket,
        //   createdAt: uploadDate,
        // };
        // setUploadedDocuments([...uploadedDocuments, base64File]);
        // console.log("Set Body==>", requestBody);
        // const formData = JSON.stringify(requestBody);
        const response = await axios.post(
          `https://marchclosing.com/api/oauth/v1/request/document?grant_type=upload`,
          {
            base64: base64File,
            reqID: ticket,
            createdAt: uploadDate,
          },
          {
            headers: {
              // "Content-Type": "multipart/form-data",
              Authorization: `bearer ${authToken} ${userId}`,
            },
          }
        );
        // Get the uploaded document information from the response
        const uploadedDocument = response.data;
        console.log("Uploaded file:", uploadedDocument);
        await axios
          .get(`https://marchclosing.com/api/oauth/v1/request/document`, {
            headers: {
              Authorization: `bearer ${authToken} ${userId} ${ticket}`,
            },
          })
          .then((documentsResponse) => {
            const documents = documentsResponse.data;
            // const updatedDocuments = documents.map((document) => ({
            //   ...document,
            //   createdAt: document.createDate, // Assuming the createDate property represents the creation date
            // }));
            setUploadedDocuments(documents);
            // console.log(updatedDocuments);
          })
          .catch((error) => {
            console.error("Error fetching uploaded documents:", error);
          });
      };
      // Read the file as a base64-encoded string
    } catch (error) {
      console.error("Error reading file:", error);
    }
  };
  const handleDocumentDelete = async (_id) => {
    axios.post(
      `https://marchclosing.com/api/oauth/v1/request/document?grant_type=delete`,
      {
        reqID: ticket,
        docID: _id,
      },
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `bearer ${authToken} ${userId}`,
        },
      }
    );
    console.log(ticket);
    await axios
      .get(`https://marchclosing.com/api/oauth/v1/request/document`, {
        headers: {
          Authorization: `bearer ${authToken} ${userId} ${ticket}`,
        },
      })
      .then((documentsResponse) => {
        const documents = documentsResponse.data;
        // const updatedDocuments = documents.map((document) => ({
        //   ...document,
        //   createdAt: document.createDate, // Assuming the createDate property represents the creation date
        // }));
        setUploadedDocuments(documents);
        // console.log(updatedDocuments);
      })
      .catch((error) => {
        console.error("Error fetching uploaded documents:", error);
      });
  };
  return (
    <div className="query-details">
      <Banner
        h1Content={`Hi  `}
        h1Span={
          <span style={{ color: "#DD3333", fontWeight: 500 }}>
            {firstName} ,{" "}
          </span>
        }
        h1Content2="welcome to your personalized dashboard"
        pageDescription="Know about request you've made and perform other relevant actions."
      />{" "}
      <Container>
        <Row>
          <Col xs={12} lg={3} md={3}>
            <div className="query-box">
              <div className="dashdivs">
                <label htmlFor="ticket">Ticket ID:</label>
                <input type="text" id="ticket" value={query.ticket} />
              </div>
              <div className="dashdivs">
                <label htmlFor="ticket">Phone:</label>
                <input type="text" id="ticket" value={phone} />
              </div>
              <div className="dashdivs">
                <label htmlFor="ticket">Email :</label>
                <input type="text" id="ticket" value={email} />
              </div>
              <div className="dashdivs">
                <label htmlFor="createdon">Created:</label>
                <input type="text" id="date" value={query.createdDate} />
              </div>
              <div className="dashdivs">
                <label htmlFor="type">Type:</label>
                <input type="text" id="type" value={query.type} />
              </div>
              <div className="dashdivs">
                <label htmlFor="currentstatus">Current Status:</label>
                <input
                  type="text"
                  id="currentstatus"
                  className={
                    query.currentStatus === "Pending" ? "Pending" : "Resolved"
                  }
                  value={query.currentStatus}
                />
              </div>
              <div className="dashdivs">
                <h4>Remarks:</h4>
                <input
                  type="text"
                  id="description"
                  value={getStatusRemarks()}
                />
              </div>
              <div className="priority-section">
                <h4>Priority:</h4>
                <select value={priority} onChange={handlePriorityChange}>
                  <option value="normal">Normal</option>
                  <option value="urgent">Urgent</option>
                </select>
              </div>
              <button className="prioritybutton">Update Priority</button>
            </div>
          </Col>
          <Col xs={12} lg={9} md={9}>
            <div className="queryrightmain">
              <div className="queryright">
                <h2>
                  <span> {query.title.toUpperCase()}</span>
                </h2>
                <h3>
                  {" "}
                  <span>{query.description}</span>
                </h3>
              </div>
            </div>
            <div className="querydocs">
              <div className="upload-section">
                <h4>Upload Document:</h4>
                <input
                  type="file"
                  onChange={handleFileUpload}
                  accept="image/*,application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </div>
              <div className="docsection">
                <div className="documents-section">
                  <h4>
                    <span>Documents Available:</span>
                  </h4>
                </div>
              </div>
              <div className="doclist">
                {" "}
                {uploadedDocuments.length > 0 ? (
                  <table className="document-table">
                    <thead>
                      <tr>
                        <th>Serial Number</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>View</th>
                        <th> Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {uploadedDocuments.map((document, index) => (
                        <tr key={document._id}>
                          <td>{index + 1}</td>
                          {/* <td>
                          <a
                            href={document.base64}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="document-link"
                          >
                            View Document
                          </a>
                          <td>
                        </td> */}
                          <td>{document.createdAt}</td>
                          <td>{document.base64.split(";")[0].split(":")[1]}</td>
                          <td>
                            <button
                              onClick={() =>
                                handleDocumentView(document.base64)
                              }
                              className="open-button"
                            >
                              View Document
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() => handleDocumentDelete(document._id)}
                              className="open-button"
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No documents uploaded yet.</p>
                )}
              </div>
            </div>
            <CommentSection ticket={ticket} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default QueryDetails;
